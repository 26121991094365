import PathHelper from '../../services/utils/PathHelper'
import ObjLoadHelper from '../../utils/ObjLoadHelper'
import ContentItemTransformer from './common/ContentItemTransformer'

export default class ObjContentLoader {
  loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    output.objects = output.objects || []

    // make relative paths absolute, if necessary
    const objFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const mtlFileUrl = PathHelper.getFullUrl(
      dataSource.url,
      contentItem.mtl || contentItem.url.slice(0, -4) + '.mtl'
    )
    const contentItemTransformer = new ContentItemTransformer(contentItem)

    ObjLoadHelper.load(
      objFileUrl,
      mtlFileUrl,
      dataSource.fileSizeResolver,
      onLoadProgress,
      (err, onFinishResult) => {
        if (err) {
          console.error(err)
        }

        for (const child of onFinishResult.children) {
          contentItemTransformer.apply(child)
          output.objects.push(child)
        }

        onLoadFinished(undefined, output)
      }
    )
  }
}
