import * as THREE from 'three'
import Color from '../utils/Color'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass'

export default class OutlineManager {
  config
  outlinePasses = {}
  outlinePassRecycleBin = []

  constructor(config) {
    this.config = config
  }

  initialize(renderer, composer) {
    this.composer = composer
    this.index = this.composer.passes.length
    this.size = renderer.getSize(new THREE.Vector2())
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
    Object.keys(this.outlinePasses).forEach((key) => {
      this.setConfig(this.outlinePasses[key])
    }, this)
  }

  update(renderManager) {
    // if there are outline passes that are pulsating, we need to render every time
    if (
      this.outlinePasses &&
      Object.keys(this.outlinePasses).length > 0 &&
      typeof this.outlinePasses === 'object'
    )
      renderManager.updateShouldRenderer(
        Object.keys(this.outlinePasses).length > 0 && this.config.renderer.borderPulsePeriod > 0
      )
  }

  setConfig(outlinePass) {
    outlinePass.edgeStrength = this.config.renderer.borderStrength
    outlinePass.edgeGlow = this.config.renderer.borderGlow
    outlinePass.edgeThickness = this.config.renderer.borderThickness
    outlinePass.pulsePeriod = this.config.renderer.borderPulsePeriod
  }

  buildColorOutlines(scene, camera) {
    const objectsByColor = this.processBorderedObjects(scene)

    // first, go over the existing passes
    // if there aren't any object with this color anymore, send the pass to the "recycle bin"
    // to improve performance
    Object.keys(this.outlinePasses).forEach((key) => {
      if (!objectsByColor[key]) {
        this.outlinePassRecycleBin.push(this.outlinePasses[key])
        this.outlinePasses[key].enabled = false
        delete this.outlinePasses[key]
      } else {
        this.outlinePasses[key].selectedObjects = objectsByColor[key]
      }
    }, this)

    // now, let's make sure all border colors have a pass, too
    // if we don't have one pass for that color, we get a pass
    // from the recycle bin or create a new pass
    Object.keys(objectsByColor).forEach((key) => {
      const actualColor = new Color(key)
      const alpha = actualColor.getAlpha()
      const color = new THREE.Color(actualColor.getRGB())

      if (alpha === 0) return

      if (!this.outlinePasses[key]) {
        let outlinePass

        if (this.outlinePassRecycleBin.length > 0) {
          outlinePass = this.outlinePassRecycleBin.pop()
          outlinePass.enabled = true
        } else {
          outlinePass = new OutlinePass(
            new THREE.Vector2(this.size.width, this.size.height),
            scene,
            camera
          )
          this.composer.insertPass(outlinePass, this.index)
        }

        outlinePass.visibleEdgeColor = color
        outlinePass.hiddenEdgeColor = color
        this.setConfig(outlinePass)
        this.outlinePasses[key] = outlinePass
      }

      this.outlinePasses[key].selectedObjects = objectsByColor[key]
    })
  }

  processBorderedObjects(object, colorDictionary) {
    if (!colorDictionary) colorDictionary = {}

    if (object.borderColor && object.visible) {
      if (!colorDictionary[object.borderColor]) {
        colorDictionary[object.borderColor] = []
      }

      colorDictionary[object.borderColor].push(object)
    } else {
      object.children.forEach((x) => this.processBorderedObjects(x, colorDictionary), this)
    }

    return colorDictionary
  }
}
