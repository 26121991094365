import * as THREE from 'three'
import Viewer3DApi from '../Viewer3DApi'
import NodeHelper from '../utils/NodeHelper'

export default class ReportManager {
  sceneManager

  constructor(sceneManager) {
    this.sceneManager = sceneManager
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.SCENE,
      {
        getReport: this.getReport
      },
      this
    )
  }

  /**
   * Produces a statistical report about the scene's loaded content, such as number of meshes, picking boxes and defined textures.
   * @api viewer3d.scene
   * @returns {ReportInfo} An object containing statistical information about the current scene.
   */
  getReport() {
    const objects = this.sceneManager.getSceneObjects()
    if (objects) {
      const report = {}

      const meshes = objects.filter((x) => x instanceof THREE.Mesh)
      const pickingBoxes = meshes.filter((x) => x.name.startsWith('PICK'))
      const nonPickingBoxes = meshes.filter((x) => !x.name.startsWith('PICK'))

      report.numObjects = objects.length
      report.objectNames = objects.map((x) => x.name)
      report.numMeshes = meshes.length
      report.numPickingBoxes = pickingBoxes.length
      report.pickingBoxNames = pickingBoxes.map((x) => x.name)
      report.meshNames = nonPickingBoxes.map((x) => x.name)
      report.meshSizes = nonPickingBoxes.map((x) => {
        return {
          name: x.name,
          numVertices: this.getNumVertices(x),
          numTriangles: this.getNumTriangles(x)
        }
      })

      report.meshSizes.sort(function (a, b) {
        return b.numVertices - a.numVertices
      })

      // If the index attribute is not set, the renderer assumes that each three contiguous positions represent a single triangle.
      report.numVertices = meshes
        .map((y) => this.getNumVertices(y))
        .reduce((total, num) => total + num)
      report.numTriangles = meshes
        .map((y) => this.getNumTriangles(y))
        .reduce((total, num) => total + num)

      report.textureNames = meshes
        .filter((x) => x.material.map)
        .map((x) => x.material.map)
        .filter((x) => x.image)
        .map((x) => x.image.src)
        .filter(NodeHelper.onlyUnique)

      return report
    }
  }

  getNumVertices(mesh) {
    return mesh.geometry.getAttribute('position').count
  }

  getNumTriangles(mesh) {
    return mesh.geometry.getAttribute('position').count / 3
  }
}
