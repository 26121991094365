import * as THREE from 'three'
import Viewer3DApi from '../Viewer3DApi'

export default class NodeStatusManager {
  config
  cameraManager
  sceneManager
  nodeManager

  constructor(config, cameraManager, sceneManager, nodeManager) {
    this.config = config
    this.cameraManager = cameraManager
    this.sceneManager = sceneManager
    this.nodeManager = nodeManager
  }

  componentDidMount(rendererDiv) {
    this.rendererDiv = rendererDiv
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.NODE,
      {
        getNodeCenter: this.getNodeCenter,
        setNodesFillColors: this.setNodesFillColors,
        setNodesBorderColors: this.setNodesBorderColors,
        setNodesEnabled: this.setNodesEnabled,
        isNodeEnabled: this.isNodeEnabled,
        hasLinkedScene: this.hasLinkedScene,
        getNodeFillColor: this.getNodeFillColor,
        getNodeBorderColor: this.getNodeBorderColor,
        getScreenPositionOfNode: this.getScreenPositionOfNode
      },
      this
    )
  }

  /**
   * Sets the base fill color of the nodes.
   * @param color {string} Fill color (css3-compatible format) to be set.
   * @param nodes {Node[]} List of nodes where to apply the color.
   * @api viewer3d.node
   */
  setNodesFillColors(color, nodes) {
    nodes.forEach((node) => {
      node.fillColor = color
      node.updateMaterials()
    }, this)
  }

  /**
   * Sets the base border color of the nodes.
   * @param color {string} Border color (css3-compatible format) to be set.
   * @param nodes {Node[]} List of nodes where to apply the color.
   * @api viewer3d.node
   */
  setNodesBorderColors(color, nodes) {
    nodes.forEach((node) => {
      node.borderColor = color
      node.updateMaterials()
    }, this)
  }

  /**
   * Enables or disables nodes.
   * @param state {boolean} True or false to enable or disable the nodes.
   * @param nodes {Node[]} List of nodes where to apply the change.
   * @api viewer3d.node
   */
  setNodesEnabled(state, nodes) {
    nodes.forEach((node) => {
      node.enabled = state
      node.updateMaterials()
    }, this)
  }

  /**
   * Indicates if the given node has a scene that will be loaded when entered.
   * @param node {Node} The node to consult.
   * @return {boolean} True if the node has such a scene link, false otherwise.
   * @api viewer3d.node
   */
  hasLinkedScene(node) {
    return Boolean(node.linkedScene)
  }

  /**
   * Indicates if the given node is enabled.
   * @param node {Node} The node to consult.
   * @return {boolean} True if the node is enabled, false otherwise.
   * @api viewer3d.node
   */
  isNodeEnabled(node) {
    return node.enabled
  }

  /**
   * Gets the fill color of a node.
   * @param node {Node} The node to consult.
   * @return {String} String representing the css3 color, as defined in the setNodeFillColor
   * @api viewer3d.node
   */
  getNodeFillColor(node) {
    return node.fillColor
  }

  /**
   * Gets the border color of a node.
   * @param node {Node} The node to consult.
   * @return {String} String representing the css3 color, as defined in the setNodeBorderColor
   * @api viewer3d.node
   */
  getNodeBorderColor(node) {
    return node.borderColor
  }

  /**
   * Gets the 3D coordinates of the center of the node.
   * @param node {Node} The node to consult.
   * @return {Vector3} ThreeJs Vector3 indicating the 3D world coordinates of the node center.
   * @api viewer3d.node
   */
  getNodeCenter(node) {
    return node.boundingBox.getCenter(new THREE.Vector3())
  }

  /**
   * Calculates the 2D screen position of the center of a given node.
   * @param node {Node}  Node from which to calculate the center.
   * @return {Vector2} ThreeJs Vector2 indicating the 2D screen coordinates.
   * @api viewer3d.node
   */
  getScreenPositionOfNode(node) {
    return this.cameraManager.getScreenPosition(node.object3d.position)
  }
}
