export default class AbstractDataSource {
  load(onLoadFinished) {
    // eslint-disable-line no-unused-vars
    // to be overridden in the subclasses
  }

  /**
   * Returns the first node to be loaded. By default, it is the root node. This can be overridden in the derived classes.
   * @returns {node}
   * @api viewer3d.datasource
   */
  getFirstScene() {
    // to be overridden in the subclasses
  }

  /**
   * Produces ThreeJS Objects corresponding to the nodes. This should be overridden in the derived classes.
   */
  getContent(node, onLoadProgress, onLoadFinished) {
    // eslint-disable-line no-unused-vars
    // to be overridden in the subclasses
  }

  /**
   *
   * @returns {SceneInfo[]}
   */
  getScenes() {
    // to be overridden in the subclasses
  }
}
