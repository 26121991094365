import * as THREE from 'three'
import SpriteCreateHelper from '../utils/SpriteCreateHelper'
import Viewer3DApi from '../Viewer3DApi'
import Marker, { MARKER_TYPE } from './nodes/Marker'

export default class MarkerManager {
  config
  sceneManager
  renderManager
  cameraManager
  nodeManager

  constructor(config, sceneManager, renderManager, cameraManager, nodeManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.renderManager = renderManager
    this.cameraManager = cameraManager
    this.nodeManager = nodeManager

    this.cameraManager.cameraChangeEvent.subscribe(this.onCameraChange, this)

    this.nodeManager.nodePreparationEvent.subscribe(this.onNodePreparation, this)
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.MARKER,
      {
        addMarker: this.addMarker,
        removeMarker: this.removeMarker,
        listMarkers: this.listMarkers
      },
      this
    )
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  componentWillUnmount() {
    this.nodeManager.nodePreparationEvent.unsubscribeAll(this)
  }

  onNodePreparation() {
    this.updateSpriteScale()
    this.buildMarkersOnSprites()
  }

  onCameraChange() {
    this.updateSpriteScale()
  }

  updateSpriteScale() {
    for (const sprite of this.sceneManager.getSceneObjects().filter((x) => x.isSprite)) {
      if (!sprite.originalScale || !sprite.relative) continue

      // since the marker is always facing the camera, we take a sized vector in the camera up direction (converted to world coordinates)
      // then we calculate the current screen size of the marker by getting the bottom and top coordinates
      // we then determine the screen size in order to determine how much the object has to be scaled in order
      // to maintain the desired size (which is the sprite's resolution * scale, as indicated in the originalScale)
      const upVector = this.cameraManager.getWorldDirection(
        new THREE.Vector3(0, sprite.originalScale.y, 0)
      )
      const baseScreenSize = this.cameraManager.getScreenPosition(sprite.position)
      const topScreenSize = this.cameraManager.getScreenPosition(
        sprite.position.clone().add(upVector)
      )

      const screenSizeY = Math.abs(baseScreenSize.y - topScreenSize.y)

      let newYScale = (sprite.originalScale.y * sprite.originalScale.y) / screenSizeY

      // we can apply minimum limits to avoid having tiny sprites
      if (sprite.minSize) newYScale = Math.max(sprite.originalScale.y * sprite.minSize, newYScale)

      // or apply maximum limits to avoid having gigantic sprites
      if (sprite.maxSize) newYScale = Math.min(sprite.originalScale.y * sprite.maxSize, newYScale)

      // it's easier and less error-prone to simply extract the x scale from the original ratio
      const newXScale = newYScale * (sprite.originalScale.x / sprite.originalScale.y)
      sprite.scale.set(newXScale, newYScale, 1)
    }

    this.renderManager.updateShouldRenderer(true)
  }

  buildMarkersOnSprites() {
    for (const sprite of this.sceneManager.getSceneObjects().filter((x) => x.isSprite)) {
      this.nodeManager.buildNode(sprite, 'MARKER', MARKER_TYPE, Marker)
    }
  }

  /**
   * Adds a sprite marker to the scene.
   * @param imagePath {string} Path string to the image
   * @param position {Vector3} Object with x, y, z fields indicating the position of the marker
   * @param scale {number} Floating point number indicating the scale of the object
   * @param offset {Vector3} Object with x, y, z fields indicating the offset of the marker, from which to draw a line. If left null,
   * no line will be drawn.
   * @param cameraState {string} Camera state to set. Can be null to avoid having the effect.
   * @param metadata {object} Extra information to set on the marker object.
   * @param persistent {boolean} Boolean indicating if the marker is persistent across different node loads. If not, the marker will
   * be automatically removed when a new node content is loaded.
   * @return {Marker} Marker object.
   * @api viewer3d.marker
   */
  addMarker(imagePath, position, scale, offset, cameraState, metadata, persistent) {
    const sprite = SpriteCreateHelper.create(imagePath, position, scale, {
      x: 0.5,
      y: 0
    })
    sprite.options = { normalImagePath: imagePath }
    sprite.metadata = metadata
    sprite.node = new Marker(sprite)
    sprite.node.persistent = persistent
    sprite.node.cameraState = cameraState
    sprite.node.applyDefaultOptions(this.config.nodeTypes)
    sprite.persistent = persistent
    sprite.isMarker = true
    sprite.loadFinished = () => {
      this.renderManager.updateShouldRenderer(true)
    }

    if (offset) {
      const offsetVector = new THREE.Vector3(offset.x, offset.y, offset.z)

      if (offsetVector.lengthSq() > 0) {
        const positionVector = new THREE.Vector3(position.x, position.y, position.z)
        const material = new THREE.LineBasicMaterial({
          color: 0xffffff,
          linewidth: 5
        })

        const geometry = new THREE.Geometry()
        geometry.vertices.push(positionVector)
        geometry.vertices.push(positionVector.clone().sub(offsetVector))

        const line = new THREE.Line(geometry, material)
        line.persistent = persistent
        sprite.line = line
        this.sceneManager.addSceneObject(line)
      }
    }

    this.sceneManager.addSceneObject(sprite)

    return sprite.node
  }

  /**
   * Removes a marker (and its offset line) from the scene.
   * @param marker {Marker} Marker to remove.
   * @api viewer3d.marker
   */
  removeMarker(marker) {
    this.sceneManager.removeSceneObject(marker.sprite)

    if (marker.sprite.line) this.sceneManager.removeSceneObject(marker.sprite.line)

    this.renderManager.updateShouldRenderer(true)
  }

  /**
   * Gets a list of all markers currently present on the scene.
   * @return {Marker[]} List of markers.
   * @api viewer3d.marker
   */
  listMarkers() {
    return this.sceneManager
      .getSceneObjects()
      .filter((x) => x.isMarker)
      .map((x) => x.node)
  }
}
