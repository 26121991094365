import * as THREE from 'three'
import SharpEvent from '../services/events/SharpEvent'
import Viewer3DApi from '../Viewer3DApi'

export default class NodeSelectionManager {
  config
  cameraManager
  sceneManager
  nodeManager
  nodeFrameManager
  rayCaster
  mouse
  nodeSelectionChangedEvent
  selectedNodes = []

  constructor(config, cameraManager, sceneManager, nodeManager, nodeFrameManager) {
    this.config = config
    this.cameraManager = cameraManager
    this.sceneManager = sceneManager
    this.nodeManager = nodeManager
    this.nodeFrameManager = nodeFrameManager

    this.rayCaster = new THREE.Raycaster()
    this.mouse = new THREE.Vector2()

    this.nodeSelectionChangedEvent = new SharpEvent()

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
  }

  componentDidMount(rendererDiv) {
    this.rendererDiv = rendererDiv
  }

  onLoadFinished() {
    this.selectedNodes = []
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  exposeAPI(api) {
    this.api = api

    api.register(
      Viewer3DApi.TYPES.NODE,
      {
        selectNode: this.selectNode,
        selectNodes: this.selectNodes,
        loadLinkedScene: this.loadLinkedScene,
        deselectAllNodes: this.deselectAllNodes,
        getSelectedNodes: this.getSelectedNodes
      },
      this
    )
  }

  /**
   * the click doesn't make a distinction between a single click and a drag..release
   * so picking could occur after a camera drag, which is not intended. This has
   * required a logic using the this.mouseMoved flag and the mouseup and mousedown events.
   */
  onMouseDown() {
    this.mouseMoved = false
  }

  onMouseMove(event) {
    const clientRect = this.rendererDiv.getBoundingClientRect()

    const previousCoords = { x: this.mouse.x, y: this.mouse.y }

    this.mouse.x = ((event.clientX - clientRect.left) / clientRect.width) * 2 - 1
    this.mouse.y = -((event.clientY - clientRect.top) / clientRect.height) * 2 + 1

    // if indeed there was a mouse movement
    if (
      Math.abs(this.mouse.x - previousCoords.x) > 0 ||
      Math.abs(this.mouse.y - previousCoords.y) > 0
    )
      this.mouseMoved = true

    // running this function before we are ready would cause bounding box calculation errors
    if (
      !this.sceneManager.sceneLoaded ||
      !this.nodeManager.hasNodesInScene ||
      this.cameraManager.animating
    )
      //
      return

    this.rayCaster.setFromCamera(this.mouse, this.cameraManager.camera)

    const items = this.sceneManager.getSceneObjects().filter((x) => x.node && x.node.enabled)
    const intersections = this.rayCaster.intersectObjects(items)
    this.intersectedObject = intersections.length > 0 ? intersections[0].object : null

    // the object that was hit will become hovered, the others will not
    for (const currentThreeObject of this.sceneManager.getSceneObjects()) {
      if (currentThreeObject.node) {
        const currentNode = currentThreeObject.node

        const newState = currentThreeObject === this.intersectedObject

        if (currentNode.hovered !== newState) {
          currentNode.hovered = newState

          if (currentNode.hovered) this.config.nodes.onNodeHover(this.api, currentNode)
          else this.config.nodes.onNodeUnhover(this.api, currentNode)

          currentNode.updateMaterials()
        }
      }
    }
  }

  onMouseUp(e) {
    if (!this.mouseMoved) {
      if (this.intersectedObject && this.intersectedObject.node)
        this.selectObject3d(
          this.intersectedObject,
          'ui',
          e.shiftKey && this.config.nodes.allowMultiSelect
        )
      else if (!e.shiftKey && this.config.nodes.clickOnVoidToDeselect) {
        this.deselectAllNodes('ui')

        this.nodeSelectionChangedEvent.invoke([])
      }
    }
  }

  onMouseDoubleClick() {
    if (this.intersectedObject && this.intersectedObject.node) {
      this.config.nodes.onNodeDoubleClick(this.api, this.intersectedObject.node)

      const linkedScene = this.intersectedObject.node.linkedScene
      if (linkedScene && this.config.nodes.doubleClickToLoad)
        this.sceneManager.loadScene(linkedScene)
    }
  }

  selectObject3d(object3d, origin, cumulative) {
    if (object3d) {
      const targetNode = object3d.node
      if (cumulative) {
        if (this.selectedNodes.includes(targetNode)) {
          this.selectedNodes = this.selectedNodes.filter((x) => x !== targetNode)

          this.config.nodes.onNodeDeselect(this.api, targetNode, origin, this.selectedNodes)
        } else {
          this.selectedNodes.push(targetNode)
          this.config.nodes.onNodeSelect(this.api, targetNode, origin, this.selectedNodes)
        }
      } else {
        this.selectedNodes = [targetNode]
        this.config.nodes.onNodeSelect(this.api, targetNode, origin, this.selectedNodes)
      }

      this.nodeSelectionChangedEvent.invoke(this.selectedNodes)

      if (this.selectedNodes.length === 1) {
        if (this.config.camera.selectionFraming) this.nodeFrameManager.frameNode(object3d.node)
      } else if (this.selectedNodes.length > 1) {
        const objects3d = this.selectedNodes.map((node) => node.object3d, this)
        const selectionBoundingBox = this.sceneManager.calculateBoundingBox(objects3d)

        if (this.config.camera.selectionFraming) this.cameraManager.frameBox(selectionBoundingBox)
      }
    }

    for (const node of this.nodeManager.getNodes()) {
      node.selected = this.selectedNodes.includes(node)

      if (node.selected) node.hovered = false

      node.updateMaterials()
    }
  }

  /**
   * Selects a node.
   * Triggers onNodeSelect event of origin 'user'.
   * @param node {Node} Node to be selected.
   * @param cumulative {boolean} Indicates if the selection is supposed to have a cumulative effect (the same addition/removal effect as if shift was pressed).
   * @api viewer3d.node
   */
  selectNode(node, cumulative) {
    this.selectObject3d(node.object3d, 'user', cumulative)
  }

  /**
   * Selects a list of nodes. Triggers onNodeSelect event of origin 'user', with the last node in the list and the nodeList filled.
   * @param nodes {Node[]} List of nodes to be selected. If empty, all nodes will be deselected.
   * @api viewer3d.node
   */
  selectNodes(nodes) {
    if (nodes.length === 0) {
      this.deselectAllNodes()
      return
    }

    const lastNode = nodes[nodes.length - 1]

    // in order to avoid calling the event many times, we select all
    this.selectedNodes = nodes.slice(0, nodes.length - 1)

    this.selectObject3d(lastNode.object3d, 'user', true)
  }

  /**
   * Loads the scene linked to a node in the currently scene, i.e. loads a scene associated to the node, if defined.
   * @param node {Node} Node to be loaded.
   * @return {boolean} True if the node has an associayed scene, false otherwise .
   * @api viewer3d.node
   */
  loadLinkedScene(node) {
    if (node.linkedScene) {
      this.sceneManager.loadScene(node.linkedScene)
      return true
    }

    return false
  }

  /**
   * Deselects all nodes.
   * @api viewer3d.node
   */
  deselectAllNodes(origin) {
    if (!this.selectedNodes.length) return

    const lastItem = this.selectedNodes[this.selectedNodes.length - 1]

    for (const selectedNode of this.selectedNodes) {
      selectedNode.selected = false
      selectedNode.updateMaterials()
    }

    this.selectedNodes = []

    origin = origin || 'user'

    this.config.nodes.onNodeDeselect(this.api, lastItem, origin, [])
  }

  /**
   * Fetches all selected nodes.
   * @return {Node[]} A list with all the selected nodes.
   * @api viewer3d.node
   */
  getSelectedNodes() {
    return Array.from(this.selectedNodes)
  }

  getMouse3DCoordinates() {
    this.rayCaster.setFromCamera(this.mouse, this.cameraManager.camera)

    const intersections = this.rayCaster.intersectObjects(this.sceneManager.group.children)

    return intersections.length > 0 ? intersections[0].point : null
  }
}
