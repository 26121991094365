export default class SceneCache {
  constructor() {
    this.isloaded = false
    this.result = null
    this.onLoadCallback = null
  }

  getContent(onLoadFinished) {
    if (this.isloaded) {
      onLoadFinished(this.result)
    } else {
      this.onLoadCallback = onLoadFinished
    }
  }

  setResult(result) {
    this.result = result
    this.isloaded = true

    if (this.onLoadCallback) {
      this.onLoadCallback(result)
    }
  }
}
