import * as THREE from 'three'
import Stats from 'stats-js'
import OutlineManager from './OutlineManager'

import Equirectangular from '../services/loaders/Equirectangular'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { SSAARenderPass } from 'three/examples/jsm/postprocessing/SSAARenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'

import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'

export default class RenderManager {
  config
  shouldRender = false
  sceneManager
  outlineManager
  rendererDivRef

  constructor(config, sceneManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.outlineManager = new OutlineManager(config)
  }

  componentDidMount(rendererDivRef) {
    this.rendererDivRef = rendererDivRef

    this.setupStats()
    this.setupRenderer(this.config)
  }

  componentWillUnmount() {
    delete this.stats
  }

  componentWillReceiveProps(nextConfig) {
    // for some heavier changes, it's better to perform a check first
    if (
      nextConfig.renderer.antialias !== this.config.renderer.antialias ||
      nextConfig.renderer.ssaaSamples !== this.config.renderer.ssaaSamples
    )
      this.setupRenderer(nextConfig)

    this.stats.domElement.style.visibility = nextConfig.renderer.showStats ? 'visible' : 'hidden'

    this.outlineManager.componentWillReceiveProps(nextConfig)

    this.updateShouldRenderer(true)

    this.config = nextConfig
  }

  setupStats() {
    this.stats = new Stats()

    this.stats.domElement.style.position = 'absolute'
    this.stats.domElement.style.top = '0px'
    this.stats.domElement.style.visibility = this.config.renderer.showStats ? 'visible' : 'hidden'

    this.rendererDivRef.appendChild(this.stats.domElement)
  }

  setupRenderer(config) {
    if (this.renderer) this.rendererDivRef.removeChild(this.renderer.domElement)

    const rendererOptions = {
      antialias: config.renderer.antialias,
      preserveDrawingBuffer: true,
      alpha: true
    }

    this.renderer = new THREE.WebGLRenderer(rendererOptions)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(0xffffff)
    this.renderer.setClearAlpha(0)

    Equirectangular.initialize(this.renderer)

    // TODO: find a possible solution for the rerendering on screen size.
    this.rendererDivRef.appendChild(this.renderer.domElement)

    this.composer = null

    this.shouldRender = true
  }

  getBaseSize() {
    return this.rendererDivRef.getBoundingClientRect().width
  }

  updateResolution(width, height) {
    this.renderer.setSize(width, height)

    this.shouldRender = true
    this.composer = null
  }

  getScreenshot() {
    return this.renderer.domElement.toDataURL('image/png')
  }

  hasRenderer() {
    return Boolean(this.renderer)
  }

  updateShouldRenderer(status) {
    // we're only interested if it becomes true
    this.shouldRender |= status
  }

  render(camera) {
    if (this.config.renderer.showStats) {
      this.stats.update()
      this.shouldRender = true
    }
    this.outlineManager.update(this)

    if (this.shouldRender) {
      // this.renderer.render(this.sceneManager.scene, camera);

      this.renderComposer(this.sceneManager.scene, camera)
    }

    this.shouldRender = false
  }

  renderComposer(scene, camera) {
    if (!this.composer) {
      // const size = this.renderer.getSize();

      this.composer = new EffectComposer(this.renderer)

      const renderPass = new RenderPass(scene, camera)
      this.composer.addPass(renderPass)

      this.ssaaRenderPass = new SSAARenderPass(scene, camera)
      this.ssaaRenderPass.sampleLevel = this.config.renderer.ssaaSamples
      this.ssaaRenderPass.unbiased = true
      this.composer.addPass(this.ssaaRenderPass)
      this.outlineManager.initialize(this.renderer, this.composer)

      this.copyPass = new ShaderPass(CopyShader)
      this.copyPass.renderToScreen = true
      this.composer.addPass(this.copyPass)
    }

    this.outlineManager.buildColorOutlines(scene, camera)
    this.composer.render()
  }
}
