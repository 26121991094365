/**
 * We could have an interface IFileSizeResolver
 */
import JSONLoader from '../../services/loaders/JSONLoader'

export default class FileSizeResolver {
  constructor(basePath, sizeSourcePath) {
    this.basePath = basePath
    this.fileSizes = {}
    this.sizeSourcePath = sizeSourcePath
  }

  async load() {
    if (!this.sizeSourcePath) {
      return undefined
    } else {
      try {
        const data = await JSONLoader.loadJsonObject(this.basePath + this.sizeSourcePath)
        this.fileSizes = data

        return data
      } catch (error) {
        return new Error('when trying to load file sizes:', error)
      }
    }
  }

  getSize(url) {
    if (this.fileSizes) {
      if (url.startsWith(this.basePath)) {
        url = url.substr(this.basePath.length)
      }

      return this.fileSizes[url]
    }

    return 0
  }
}
