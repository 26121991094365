export class NodeInfo {
  name
  meta
  targetModels
  type
  alias
  enabled
  visible
  linkedScene
  options
  cameraState

  constructor(name) {
    this.name = name
    this.enabled = true
    this.visible = true
  }
}
