import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { MTLLoader } from '../model/loaders/MTLLoader'
export default class ObjLoadHelper {
  objResult
  /**
   *Loads a Wavefront .obj file from a given url.
   * @param objFilePath Full file path/url to the obj file.
   * @param mtlFilePath Full file path/url to the mtl file.
   * @param fileSizeResolver
   * @param onLoadProgress Callback for file loading progress, as function(progress){}
   * @param onLoadFinished Callback for file loading finish, as function(err,result){}
   */
  static async load(objFilePath, mtlFilePath, fileSizeResolver, onLoadProgress, onLoadFinished) {
    try {
      const mtlBaseFolderPath = mtlFilePath.substring(0, mtlFilePath.lastIndexOf('/') + 1)
      const mtlFileName = mtlFilePath.replace(mtlBaseFolderPath, '')
      const objFileName = objFilePath.replace(mtlBaseFolderPath, '')
      const mtlLoader = new MTLLoader()
      const objLoader = new OBJLoader()
      mtlLoader.setPath(mtlBaseFolderPath)
      objLoader.setPath(mtlBaseFolderPath)

      mtlLoader.setCrossOrigin('*')
      const mtl = await mtlLoader.loadAsync(mtlFileName, (progress) => {
        onLoadProgress(progress.loaded / progress.total)
      })

      mtl.preload()

      objLoader.setMaterials(mtl)
      const obj = await objLoader.loadAsync(objFileName, (progress) => {
        onLoadProgress(progress.loaded / progress.total)
      })

      onLoadFinished(undefined, obj)
    } catch (e) {
      console.error('ObjLoadHelper Error: ', e)
    }
  }
}
