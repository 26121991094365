import axios from 'axios'
import JSON5 from 'json5'

export default class JSONLoader {
  /**
   * Loads a remote json file from a given url, parsing into a json object, and throwing and error if it didn't succeed.
   * @param url Location of the json file.
   * @param onLoadFinished Callback when the load is finished.
   */
  static async loadJsonObject(url) {
    try {
      const res = await axios.get(url)

      if (typeof res.data !== 'object') {
        return JSON5.parse(res.data)
      }

      return res.data
    } catch (error) {
      console.error('JSONLOADER', error)
      return error
    }
  }
}
