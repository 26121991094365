import AbstractDataSource from './AbstractDataSource'
import ObjContentLoader from './loaders/ObjContentLoader'
import FbxContentLoader from './loaders/FbxContentLoader'
import GltfContentLoader from './loaders/GltfMarkerLoader'
import MarkerContentLoader from './loaders/MarkerContentLoader'
import SkinContentLoader from './loaders/SkinContentLoader'
import VideoContentLoader from './loaders/VideoContentLoader'
import LightContentLoader from './loaders/LightContentLoader'
import FileSizeResolver from '../services/loaders/FileSizeResolver'
import JSONLoader from '../services/loaders/JSONLoader'
import { SceneInfo } from './definitions/SceneInfo'
import { NodeInfo } from './definitions/NodeInfo'
import SceneCache from './caching/SceneCache'

import { find } from 'lodash'

const PrefetchMode = {
  All: 'all',
  First: 'first'
}

/**
 * This datasource is the base example of how to develop data sources.
 * Data source must include the means to load the data, indicate how they map into nodes and
 * how they result into THREE.Js compatible content.
 *
 * This class to the base example of json specification that was provided in the old
 * 3D Viewer.
 */
export default class SceneDataSource extends AbstractDataSource {
  constructor() {
    super()

    this.url = 'http://localhost:3000/'
    this.path = 'data.json'
    this.sizes = null
    this.initialSceneId = null
    this.enableDoubleSide = false
    this.enableWireframe = false
    this.useCaching = true
    this.preFetch = PrefetchMode.First
    this.disposed = false

    this.contentBySceneIdMap = {}
    this.scenes = []

    this.loaders = {
      obj: new ObjContentLoader(),
      fbx: new FbxContentLoader(),
      gltf: new GltfContentLoader(),
      markers: new MarkerContentLoader(),
      skins: new SkinContentLoader(),
      videos: new VideoContentLoader(),
      lights: new LightContentLoader()
    }
  }

  /**
   * The process starts here. The file is downloaded and parsed.
   * @param onLoadFinished
   */
  load(onLoadFinished) {
    this.fileSizeResolver = new FileSizeResolver(this.url, this.sizes)

    const promise = this.fileSizeResolver.load()

    promise.then(() => {
      JSONLoader.loadJsonObject(this.url + this.path).then((data) => {
        this.scenes = []

        for (const key of Object.keys(data)) {
          this.scenes.push(this.processData(key, data[key]))
        }

        if (this.preFetch) {
          const scenesToPrefetch =
            this.preFetch === PrefetchMode.All ? this.getScenes() : [this.getFirstScene()]

          if (this.useCaching) {
            for (const scene of scenesToPrefetch) {
              this.contentBySceneIdMap[scene.name] = new SceneCache()
            }
          }

          this.prefetchScenes(scenesToPrefetch, () => {
            if (!this.disposed) onLoadFinished()
          })
        } else {
          // let firstNode = this.getFirstNode();

          if (!this.disposed) onLoadFinished()
        }
      })
    })
  }

  prefetchScenes(scenes, onLoadFinished, requestedScene) {
    if (scenes.length === 0) {
      // in case we already loaded the intended targetScene
      // onLoadFinished will be set to null, meaning that the
      // end of this process is not relevant
      if (onLoadFinished) onLoadFinished()

      return
    }

    const dataSource = this
    const scene = scenes.shift()

    this.loadContent(
      scene,
      () => {},
      (err, result) => {
        if (dataSource.useCaching) dataSource.contentBySceneIdMap[scene.name].setResult(result)

        if (scene === requestedScene) {
          onLoadFinished()
          dataSource.prefetchScenes(scenes, null)
        } else {
          dataSource.prefetchScenes(scenes, onLoadFinished, requestedScene)
        }
      }
    )
  }

  processData(sceneName, scene) {
    // TODO: Remove after version 2.0.0
    if (scene.children) {
      console.warn(
        'scene.children is no longer supported. Please switch to the new "nodes" definition.'
      )
    }

    const sceneInfo = Object.assign(new SceneInfo(sceneName), scene)
    if (sceneInfo.nodes) {
      for (const key of Object.keys(sceneInfo.nodes)) {
        sceneInfo.nodes[key] = Object.assign(new NodeInfo(key), sceneInfo.nodes[key])
      }
    }

    return sceneInfo
  }

  getFirstScene() {
    if (this.initialSceneId) return find(this.scenes, { id: this.initialSceneId })
    else {
      return find(this.scenes, { default: true }) || this.scenes[0]
    }
  }

  /**
   * Gets the list of loaded Scenes.
   * @returns {SceneInfo[]} List of loaded scenes.
   */
  getScenes() {
    return this.scenes
  }

  getContent(scene, onLoadProgress, onLoadFinished) {
    if (this.useCaching && this.contentBySceneIdMap[scene.name]) {
      this.contentBySceneIdMap[scene.name].getContent((result) => {
        if (!this.disposed) onLoadFinished(undefined, result)
      })
    } else {
      this.loadContent(scene, onLoadProgress, (err, result) => {
        if (this.useCaching) {
          const nodeCache = (this.contentBySceneIdMap[scene.name] = new SceneCache())
          nodeCache.setResult(result)
        }

        if (!this.disposed) onLoadFinished(undefined, result)
      })
    }
  }

  loadContent(scene, onLoadProgress, onFinished) {
    let promise = Promise.resolve({})

    // start the progress bar, even if it is just to state that we don't know
    // progress yet
    onLoadProgress(Number.POSITIVE_INFINITY)

    const dataSource = this

    if (scene.content) {
      for (const contentItem of scene.content) {
        if (contentItem.url) {
          const extension = contentItem.url.split('.').pop().toLowerCase()
          const type = contentItem.type
          const loader = this.loaders[extension] || this.loaders[type]

          if (!loader)
            throw new Error(
              "Could not find a loader for the extension '" +
                extension +
                "' or type '" +
                type +
                "'. Accepted values are " +
                Object.keys(this.loaders).join(',') +
                '.'
            )

          promise = promise.then(
            (previousResult) =>
              new Promise((resolve) => {
                loader.loadContent(
                  contentItem,
                  previousResult,
                  dataSource,
                  onLoadProgress,
                  (err, result) => {
                    resolve(result)
                  }
                )
              })
          )
        }
      }
    }

    promise.then((result) => {
      onFinished(undefined, result)
    })
  }
}
