/**
 * The SharpEvent is a C#-inspired implementation of an event system. One should create an object of this class for each
 * event, such as "this.onChangeEvent = new SharpEvent()", which other class can access directly for subscription and
 * unsubscription. The advantage of this system (when compared with the event emitter) are many:
 * - No repetition: No need to reimplement add,emit and remove functions at every class;
 * - Less verbose: bind(this) is done internally, so there's no need to keep a reference to the original callback and the bound this;
 * - Safer: unsubscription can be performed on object level;
 * - IDE friendly: IDEs can more easily suggest event variables (especially if you follow a naming standard such as 'onChangeEvent') than random strings for event names;
 * - Same performance: The cpu and memory overhead is the pretty much the same.
 * - Simplicity: Usage is simple, as well as its implementation.
 */
export default class SharpEvent {
  callbackItems = []

  /**
   * Invokes the event.
   * @param args The args to pass to the function.
   */
  invoke(...args) {
    this.callbackItems.forEach((item) => item.callback(...args))
    this.callbackItems = this.callbackItems.filter((item) => !item.singleUse)
  }

  /**
   * Subscribes a callback.
   * @param callback The callback to add.
   * @param thisObject (Optional) If defined, a bind(thisObject) will be performed. It it also useful to unsubscribe all callbacks from that object.
   */
  subscribe(callback, thisObject) {
    this.callbackItems.push({
      thisObject: thisObject,
      originalCallback: callback,
      callback: thisObject ? callback.bind(thisObject) : callback
    })
    return this
  }

  /**
   * Subscribes a callback for one use.
   * @param callback The callback to add.
   * @param thisObject (Optional) If defined, a bind(thisObject) will be performed. It it also useful to unsubscribe all callbacks from that object.
   */
  subscribeOnce(callback, thisObject) {
    this.callbackItems.push({
      thisObject: thisObject,
      originalCallback: callback,
      callback: thisObject ? callback.bind(thisObject) : callback,
      singleUse: true
    })
    return this
  }

  /**
   * Unsubscribes a callback (all or just from a given object).
   * @param callback The callback to remove
   * @param thisObject (Optional) If defined, will unsubscribe all callbacks belonging to 'thisObject', or all otherwise.
   */
  unsubscribe(callback, thisObject) {
    this.callbackItems = thisObject
      ? this.callbackItems.filter(
          (item) => item.thisObject !== thisObject || item.originalCallback !== callback
        )
      : this.callbackItems.filter((item) => item.originalCallback !== callback)

    return this
  }

  /**
   * Unsubscribes all callbacks (all or just from a given object).
   * @param thisObject (Optional) If defined, will unsubscribe all callbacks belonging to 'thisObject', or all otherwise.
   */
  unsubscribeAll(thisObject) {
    this.callbackItems = thisObject
      ? this.callbackItems.filter((item) => item.thisObject !== thisObject)
      : []
    return this
  }
}
