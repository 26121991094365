import ReactResizeDetector from 'react-resize-detector'
import React from 'react'

/**
 * The CanvasResizeWatch is the component that watches for changes in size
 * outside the viewer div and updates other components.
 */
export default class CanvasResizeWatcher extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      config: props.config,
      renderManager: props.renderManager,
      cameraManager: props.cameraManager
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    const nextConfig = nextProps.config

    if (
      nextConfig.renderer.ratio !== state.config.renderer.ratio ||
      nextConfig.camera.fov !== state.config.camera.fov ||
      nextConfig.renderer.width !== state.config.renderer.width ||
      nextConfig.renderer.height !== state.config.renderer.height
    ) {
      CanvasResizeWatcher.updateCanvasSize(state.renderManager.getBaseSize(), nextConfig, state)
    }

    return { config: nextConfig }
  }

  static updateCanvasSize(width, config, state) {
    if (state.renderManager.hasRenderer()) {

      width = config.renderer.width || width

      const height = config.renderer.height || width / config.renderer.ratio

      state.renderManager.updateResolution(width, height)
      state.cameraManager.updateFov(width, height)
    }
  }

  render() {
    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(width) =>
          CanvasResizeWatcher.updateCanvasSize(width, this.state.config, this.state)
        }
      />
    )
  }
}
