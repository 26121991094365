import { isEqual, merge } from 'lodash'

export class SceneOptionsManager {
  constructor(config, sceneManager, managers) {
    this.config = config
    this.previousConfig = config
    this.currentSceneConfig = {}

    this.sceneManager = sceneManager
    this.managers = managers

    this.sceneManager.onSceneChanged.subscribe(this.onSceneChanged, this)
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
    this.refreshComponentOptions()
  }

  onSceneChanged(scene) {
    this.currentSceneConfig = scene.options || {}

    this.refreshComponentOptions()
  }

  refreshComponentOptions() {
    const nextConfig = merge({}, this.config, this.currentSceneConfig)

    // skip the rest of the verifications if nothing changed
    if (isEqual(this.previousConfig, nextConfig)) return

    for (const manager of this.managers.filter((x) => x.componentWillReceiveProps)) {
      manager.componentWillReceiveProps(nextConfig)
    }

    this.previousConfig = nextConfig
  }
}
