import React from 'react'

export default class ProgressRenderer extends React.Component {
  constructor(props) {
    super(props)

    this.sceneManager = props.sceneManager
    this.renderManager = props.renderManager

    this.sceneManager.onLoadProgressEvent.subscribe(this.onModelLoadProgress.bind(this), this)
    this.sceneManager.onLoadFinishEvent.subscribe(this.onModelLoadFinished.bind(this), this)

    this.state = {
      isLoading: false,
      loadingProgress: 0,
      loadingMessage: ''
    }
  }

  /**
   * Event that updates the progress control during the node loading process.
   * @param progress
   */
  onModelLoadProgress(progress) {
    this.setState({
      isLoading: true,
      loadingProgress: progress,
      loadingMessage: ''
    })
  }

  /**
   * Event that hides the progress control when the node loading process is finished.
   */
  onModelLoadFinished() {
    this.setState({ isLoading: false })
    this.renderManager.updateShouldRenderer(true)
  }

  componentWillUnmount() {
    this.sceneManager.onLoadProgressEvent.unsubscribeAll(this)
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
  }

  render() {
    if (this.props.showProgress && this.state.isLoading) {
      const value = Number.isFinite(this.state.loadingProgress)
        ? Math.floor(this.state.loadingProgress * 100) + '%'
        : ''
      const color = this.props.color
      const style = { borderTopColor: color }

      return (
        <span>
          <span style={{ color: color }} className='lds-text'>
            {value}
          </span>
          <div style={style} className='lds-ring'>
            <div style={style} />
            <div style={style} />
            <div style={style} />
            <div style={style} />
          </div>
        </span>
      )
    }

    return null
  }
}
