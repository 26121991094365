import * as THREE from 'three'
const events = require('events')

// patterns
const isHex = /^#?([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i

const isHSL = /^hsl?\((\d{0,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/
const isHSLA = /^hsla?\((\d{0,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*([01](\.\d+)*)\)$/

const isRGB = /^rgb?\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?)\)$/
const isRGBA = /^rgba?\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*([01]?\.?\d*)\)$/

const isPercent = /^\d+(\.\d+)*%$/

const hexBit = /([0-9a-f])/gi
const leadHex = /^#/

const matchHSL = /^hsl?\((\d{0,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/
const matchHSLA = /^hsla?\((\d{0,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*([01](\.\d+)*)\)$/

const matchRGB = /^rgb?\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?)\)$/
const matchRGBA = /^rgba?\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*([01]?\.?\d*)\)$/

export default class Color {
  constructor(value) {
    // Event Management
    this.listeners = {}

    this._value = 0
    this._hex = '#000000'
    this._red = 0 // 0 - 255
    this._green = 0 // 0 - 255
    this._blue = 0 // 0 - 255
    this._hue = 0 // 0 - 360
    this._saturation = 0 // 0 - 100
    this._lightness = 0 // 0 - 100
    this._brightness = 0 // 0 - 100
    this._alpha = 1 // 0 - 1

    this.output = 0

    this.subscribe(Color.Events.RGB_UPDATED, this.RGBUpdated)
    this.subscribe(Color.Events.HEX_UPDATED, this.HEXUpdated)
    this.subscribe(Color.Events.HSL_UPDATED, this.HSLUpdated)
    this.subscribe(Color.Events.HSV_UPDATED, this.HSVUpdated)
    this.subscribe(Color.Events.INT_UPDATED, this.INTUpdated)

    this.parse(value)
  }

  // helpers
  static absround(number) {
    return number << 0
  }

  static hue2rgb(a, b, c) {
    // http://www.w3.org/TR/css3-color/#hsl-color
    if (c < 0) c += 1
    if (c > 1) c -= 1
    if (c < 1 / 6) return a + (b - a) * 6 * c
    if (c < 1 / 2) return b
    if (c < 2 / 3) return a + (b - a) * (2 / 3 - c) * 6
    return a
  }

  static perc2value(p) {
    return isPercent.test(p) ? Color.absround(parseInt(p) * 2.55) : p
  }

  parse(value) {
    if (typeof value === 'undefined') {
      return this
    }

    switch (true) {
      case isFinite(value):
        return this.value(value)
      case value instanceof Color:
        return this.copy(value)
      case isHex.test(value): {
        let stripped = value.replace(leadHex, '')
        if (stripped.length === 3) {
          stripped = stripped.replace(hexBit, '$1$1')
        } else if (stripped.length === 8) {
          const alphaHex = parseInt(stripped.substr(6), 16)
          stripped = stripped.substr(0, 6)
          this.alpha(alphaHex / 255)
        }
        return this.value(parseInt(stripped, 16))
      }
      case isRGBA.test(value): {
        const parts = value.match(matchRGBA)
        this.red(Color.perc2value(parts[1]))
        this.green(Color.perc2value(parts[2]))
        this.blue(Color.perc2value(parts[3]))
        this.alpha(parseFloat(parts[4]))
        return this.value()
      }
      case isRGB.test(value): {
        const parts = value.match(matchRGB)
        this.red(Color.perc2value(parts[1]))
        this.green(Color.perc2value(parts[2]))
        this.blue(Color.perc2value(parts[3]))
        this.alpha(1)
        return this.value()
      }
      case isHSLA.test(value): {
        // http://www.w3.org/TR/css3-color/#hsl-color
        const parts = value.match(matchHSLA)
        this.hue(parseInt(parts[1]))
        this.saturation(parseInt(parts[2]))
        this.lightness(parseInt(parts[3]))
        this.alpha(parseInt(parts[4]) || 1)
        return this.value()
      }
      case isHSL.test(value): {
        // http://www.w3.org/TR/css3-color/#hsl-color
        const parts = value.match(matchHSL)
        this.hue(parseInt(parts[1]))
        this.saturation(parseInt(parts[2]))
        this.lightness(parseInt(parts[3]))
        this.alpha(1)
        return this.value()
      }
      case Object.prototype.hasOwnProperty.call(Color.namedColors, value): {
        value = Color.namedColors[value]
        const stripped = value.replace(leadHex, '')
        return this.value(parseInt(stripped, 16))
      }
      case typeof value === 'object':
        return this.set(value)
    }

    return this
  }

  clone() {
    return new Color(this.value())
  }

  copy(color) {
    return this.set(color.value())
  }

  set(key, value) {
    if (arguments.length === 1) {
      if (typeof key === 'object') {
        for (const p in key) {
          if (typeof this[p] === 'function') {
            this[p](key[p])
          }
        }
      } else if (isFinite(key)) {
        this.value(key)
      }
    } else if (typeof this[key] === 'function') {
      this[key](value)
    }

    return this
  }

  toValue() {
    return this._value
  }

  interpolate(v, f) {
    if (!(v instanceof Color)) {
      v = new Color(v)
    }

    this._red = this._red + (v._red - this._red) * f
    this._green = this._green + (v._green - this._green) * f
    this._blue = this._blue + (v._blue - this._blue) * f
    this.broadcast(events.RGB_UPDATED)
    return this
  }

  RGB2HSL() {
    const r = this._red / 255
    const g = this._green / 255
    const b = this._blue / 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const l = (max + min) / 2
    const v = max

    if (max === min) {
      this._hue = 0
      this._saturation = 0
      this._lightness = Color.absround(l * 100)
      this._brightness = Color.absround(v * 100)
      return
    }

    const d = max - min
    const s = d / (l <= 0.5 ? max + min : 2 - max - min)
    const h =
      (max === r ? (g - b) / d + (g < b ? 6 : 0) : max === g ? (b - r) / d + 2 : (r - g) / d + 4) /
      6

    this._hue = Color.absround(h * 360)
    this._saturation = Color.absround(s * 100)
    this._lightness = Color.absround(l * 100)
    this._brightness = Color.absround(v * 100)
  }

  HSL2RGB() {
    const h = this._hue / 360
    const s = this._saturation / 100
    const l = this._lightness / 100
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q
    this._red = Color.absround(Color.hue2rgb(p, q, h + 1 / 3) * 255)
    this._green = Color.absround(Color.hue2rgb(p, q, h) * 255)
    this._blue = Color.absround(Color.hue2rgb(p, q, h - 1 / 3) * 255)
  }

  HSV2RGB() {
    // http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
    const h = this._hue / 360
    const s = this._saturation / 100
    const v = this._brightness / 100
    let r = 0
    let g = 0
    let b = 0
    const i = Color.absround(h * 6)
    const f = h * 6 - i
    const p = v * (1 - s)
    const q = v * (1 - f * s)
    const t = v * (1 - (1 - f) * s)
    switch (i % 6) {
      case 0:
        r = v
        g = t
        b = p
        break
      case 1:
        r = q
        g = v
        b = p
        break
      case 2:
        r = p
        g = v
        b = t
        break
      case 3:
        r = p
        g = q
        b = v
        break
      case 4:
        r = t
        g = p
        b = v
        break
      case 5:
        r = v
        g = p
        b = q
        break
    }
    this._red = Color.absround(r * 255)
    this._green = Color.absround(g * 255)
    this._blue = Color.absround(b * 255)
  }

  INT2HEX() {
    let x = this._value.toString(16)
    x = '000000'.substr(0, 6 - x.length) + x
    this._hex = '#' + x.toUpperCase()
  }

  INT2RGB() {
    this._red = this._value >> 16
    this._green = (this._value >> 8) & 0xff
    this._blue = this._value & 0xff
  }

  HEX2INT() {
    this._value = parseInt(this._hex, 16)
  }

  RGB2INT() {
    this._value = (this._red << 16) | ((this._green << 8) & 0xffff) | this._blue
  }

  RGBUpdated() {
    this.RGB2INT() // populate INT values
    this.RGB2HSL() // populate HSL values
    this.INT2HEX() // populate HEX values
  }

  HSLUpdated() {
    this.HSL2RGB() // populate RGB values
    this.RGB2INT() // populate INT values
    this.INT2HEX() // populate HEX values
  }

  HSVUpdated() {
    this.HSV2RGB() // populate RGB values
    this.RGB2INT() // populate INT values
    this.INT2HEX() // populate HEX values
  }

  HEXUpdated() {
    this.HEX2INT() // populate INT values
    this.INT2RGB() // populate RGB values
    this.RGB2HSL() // populate HSL values
  }

  INTUpdated() {
    this.INT2RGB() // populate RGB values
    this.RGB2HSL() // populate HSL values
    this.INT2HEX() // populate HEX values
  }

  value(value) {
    return this._handle('_value', value, Color.Events.INT_UPDATED)
  }

  hex(value) {
    return this._handle('_hex', value, Color.Events.HEX_UPDATED)
  }

  red(value) {
    return this._handle('_red', value, Color.Events.RGB_UPDATED)
  }

  green(value) {
    return this._handle('_green', value, Color.Events.RGB_UPDATED)
  }

  blue(value) {
    return this._handle('_blue', value, Color.Events.RGB_UPDATED)
  }

  hue(value) {
    return this._handle('_hue', value, Color.Events.HSL_UPDATED)
  }

  saturation(value) {
    return this._handle('_saturation', value, Color.Events.HSL_UPDATED)
  }

  lightness(value) {
    return this._handle('_lightness', value, Color.Events.HSL_UPDATED)
  }

  brightness(value) {
    return this._handle('_brightness', value, Color.Events.HSV_UPDATED)
  }

  alpha(value) {
    return this._handle('_alpha', value)
  }

  _handle(prop, value, event) {
    if (typeof this[prop] !== 'undefined') {
      if (typeof value !== 'undefined') {
        if (value !== this[prop]) {
          this[prop] = value
          if (event) {
            this.broadcast(event)
          }
        }
      }
    }

    return this[prop]
  }

  // convert to CSS values
  getHex() {
    return this._hex
  }

  getAlpha() {
    return this._alpha
  }

  getRGB() {
    const components = [
      Color.absround(this._red),
      Color.absround(this._green),
      Color.absround(this._blue)
    ]
    return 'rgb(' + components.join(', ') + ')'
  }

  getThreeColor() {
    return new THREE.Color(this._hex)
  }

  getPRGB() {
    const components = [
      Color.absround(this._red / 255) + '%',
      Color.absround(this._green / 255) + '%',
      Color.absround(this._blue / 255) + '%'
    ]
    return 'rgb(' + components.join(', ') + ')'
  }

  getRGBA() {
    const components = [
      Color.absround(this._red),
      Color.absround(this._green),
      Color.absround(this._blue),
      this._alpha
    ]
    return 'rgba(' + components.join(', ') + ')'
  }

  getPRGBA() {
    const components = [
      Color.absround(this._red / 255) + '%',
      Color.absround(this._green / 255) + '%',
      Color.absround(this._blue / 255) + '%',
      this._alpha
    ]
    return 'rgba(' + components.join(', ') + ')'
  }

  getHSL() {
    const components = [
      Color.absround(this._hue),
      Color.absround(this._saturation) + '%',
      Color.absround(this._lightness) + '%'
    ]
    return 'hsl(' + components.join(', ') + ')'
  }

  getHSLA() {
    const components = [
      Color.absround(this._hue),
      Color.absround(this._saturation) + '%',
      Color.absround(this._lightness) + '%',
      this._alpha
    ]
    return 'hsla(' + components.join(', ') + ')'
  }

  format(string) {
    const tokens = {
      r: this._red,
      g: this._green,
      b: this._blue,
      h: this._hue,
      s: this._saturation,
      l: this._lightness,
      v: this._brightness,
      a: this._alpha,
      x: this._hex,
      i: this._value
    }
    for (const token in tokens) {
      string = string.split('%' + token + '%').join(tokens[token])
    }

    return string
  }

  toString() {
    switch (this.output) {
      case 0: // Color.HEX
        return this.getHex()
      case 1: // Color.RGB
        return this.getRGB()
      case 2: // Color.PRGB
        return this.getPRGB()
      case 3: // Color.RGBA
        return this.getRGBA()
      case 4: // Color.PRGBA
        return this.getPRGBA()
      case 5: // Color.HSL
        return this.getHSL()
      case 6: // Color.HSLA
        return this.getHSLA()
    }
  }

  isSubscribed(type) {
    return this.listeners[type] != null
  }

  subscribe(type, callback) {
    if (!this.isSubscribed(type)) {
      this.listeners[type] = []
    }

    this.listeners[type].push(callback)
  }

  unsubscribe(type, callback) {
    if (!this.isSubscribed(type)) {
      return
    }

    const stack = this.listeners[type]

    for (let i = 0; i < stack.length; i++) {
      if (stack[i] === callback) {
        stack.splice(i, 1)
        return this.unsubscribe(type, callback)
      }
    }
  }

  broadcast(type, params) {
    if (!this.isSubscribed(type)) {
      return
    }
    const stack = this.listeners[type]
    const l = stack.length
    for (let i = 0; i < l; i++) {
      stack[i].apply(this, params)
    }
  }
}

Color.Events = {
  RGB_UPDATED: 'RGBUpdated',
  HSL_UPDATED: 'HSLUpdated',
  HSV_UPDATED: 'HSVUpdated',
  HEX_UPDATED: 'HexUpdated',
  INT_UPDATED: 'IntUpdated'
}

Color.namedColors = {
  transparent: 'rgba(0, 0, 0, 0)',
  aliceblue: '#F0F8FF',
  antiquewhite: '#FAEBD7',
  aqua: '#00FFFF',
  aquamarine: '#7FFFD4',
  azure: '#F0FFFF',
  beige: '#F5F5DC',
  bisque: '#FFE4C4',
  black: '#000000',
  blanchedalmond: '#FFEBCD',
  blue: '#0000FF',
  blueviolet: '#8A2BE2',
  brown: '#A52A2A',
  burlywood: '#DEB887',
  cadetblue: '#5F9EA0',
  chartreuse: '#7FFF00',
  chocolate: '#D2691E',
  coral: '#FF7F50',
  cornflowerblue: '#6495ED',
  cornsilk: '#FFF8DC',
  crimson: '#DC143C',
  cyan: '#00FFFF',
  darkblue: '#00008B',
  darkcyan: '#008B8B',
  darkgoldenrod: '#B8860B',
  darkgray: '#A9A9A9',
  darkgrey: '#A9A9A9',
  darkgreen: '#006400',
  darkkhaki: '#BDB76B',
  darkmagenta: '#8B008B',
  darkolivegreen: '#556B2F',
  darkorange: '#FF8C00',
  darkorchid: '#9932CC',
  darkred: '#8B0000',
  darksalmon: '#E9967A',
  darkseagreen: '#8FBC8F',
  darkslateblue: '#483D8B',
  darkslategray: '#2F4F4F',
  darkslategrey: '#2F4F4F',
  darkturquoise: '#00CED1',
  darkviolet: '#9400D3',
  deeppink: '#FF1493',
  deepskyblue: '#00BFFF',
  dimgray: '#696969',
  dimgrey: '#696969',
  dodgerblue: '#1E90FF',
  firebrick: '#B22222',
  floralwhite: '#FFFAF0',
  forestgreen: '#228B22',
  fuchsia: '#FF00FF',
  gainsboro: '#DCDCDC',
  ghostwhite: '#F8F8FF',
  gold: '#FFD700',
  goldenrod: '#DAA520',
  gray: '#808080',
  grey: '#808080',
  green: '#008000',
  greenyellow: '#ADFF2F',
  honeydew: '#F0FFF0',
  hotpink: '#FF69B4',
  indianred: '#CD5C5C',
  indigo: '#4B0082',
  ivory: '#FFFFF0',
  khaki: '#F0E68C',
  lavender: '#E6E6FA',
  lavenderblush: '#FFF0F5',
  lawngreen: '#7CFC00',
  lemonchiffon: '#FFFACD',
  lightblue: '#ADD8E6',
  lightcoral: '#F08080',
  lightcyan: '#E0FFFF',
  lightgoldenrodyellow: '#FAFAD2',
  lightgray: '#D3D3D3',
  lightgrey: '#D3D3D3',
  lightgreen: '#90EE90',
  lightpink: '#FFB6C1',
  lightsalmon: '#FFA07A',
  lightseagreen: '#20B2AA',
  lightskyblue: '#87CEFA',
  lightslategray: '#778899',
  lightslategrey: '#778899',
  lightsteelblue: '#B0C4DE',
  lightyellow: '#FFFFE0',
  lime: '#00FF00',
  limegreen: '#32CD32',
  linen: '#FAF0E6',
  magenta: '#FF00FF',
  maroon: '#800000',
  mediumaquamarine: '#66CDAA',
  mediumblue: '#0000CD',
  mediumorchid: '#BA55D3',
  mediumpurple: '#9370D8',
  mediumseagreen: '#3CB371',
  mediumslateblue: '#7B68EE',
  mediumspringgreen: '#00FA9A',
  mediumturquoise: '#48D1CC',
  mediumvioletred: '#C71585',
  midnightblue: '#191970',
  mintcream: '#F5FFFA',
  mistyrose: '#FFE4E1',
  moccasin: '#FFE4B5',
  navajowhite: '#FFDEAD',
  navy: '#000080',
  oldlace: '#FDF5E6',
  olive: '#808000',
  olivedrab: '#6B8E23',
  orange: '#FFA500',
  orangered: '#FF4500',
  orchid: '#DA70D6',
  palegoldenrod: '#EEE8AA',
  palegreen: '#98FB98',
  paleturquoise: '#AFEEEE',
  palevioletred: '#D87093',
  papayawhip: '#FFEFD5',
  peachpuff: '#FFDAB9',
  peru: '#CD853F',
  pink: '#FFC0CB',
  plum: '#DDA0DD',
  powderblue: '#B0E0E6',
  purple: '#800080',
  red: '#FF0000',
  rosybrown: '#BC8F8F',
  royalblue: '#4169E1',
  saddlebrown: '#8B4513',
  salmon: '#FA8072',
  sandybrown: '#F4A460',
  seagreen: '#2E8B57',
  seashell: '#FFF5EE',
  sienna: '#A0522D',
  silver: '#C0C0C0',
  skyblue: '#87CEEB',
  slateblue: '#6A5ACD',
  slategray: '#708090',
  slategrey: '#708090',
  snow: '#FFFAFA',
  springgreen: '#00FF7F',
  steelblue: '#4682B4',
  tan: '#D2B48C',
  teal: '#008080',
  thistle: '#D8BFD8',
  tomato: '#FF6347',
  turquoise: '#40E0D0',
  violet: '#EE82EE'
}

Color.HEX = 0 // toString returns hex: #ABC123
Color.RGB = 1 // toString returns rgb: rgb(0, 100, 255)
Color.PRGB = 2 // toString returns percent rgb: rgb(0%, 40%, 100%)
Color.RGBA = 3 // toString returns rgba: rgba(0, 100, 255, 0.5)
Color.PRGBA = 4 // toString returns percent rgba: rgba(0%, 40%, 100%, 0.5)
Color.HSL = 5 // toString returns hsl: hsl(360, 50%, 50%)
Color.HSLA = 6 // toString returns hsla: hsla(360, 50%, 50%, 0.5)
