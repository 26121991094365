import * as THREE from 'three'
import Color from '../utils/Color'

export default class {
  scene
  config
  ambientLight
  cameraManager
  renderContentModifiedEvent
  axesHelper
  targetHelper

  constructor(sceneManager, cameraManager) {
    this.scene = sceneManager.scene
    this.config = sceneManager.config
    this.cameraManager = cameraManager
    this.renderContentModifiedEvent = sceneManager.renderContentModifiedEvent

    this.axesHelper = new THREE.AxesHelper(1000)
    this.axesHelper.name = "AxesHelper"
    this.axesHelper.visible = this.config.renderer.axesHelper
    this.scene.add(this.axesHelper)

    this.ambientLight = new THREE.AmbientLight(this.config.light.color, this.config.light.intensity)
    this.scene.add(this.ambientLight)

    const sphere = new THREE.SphereBufferGeometry(2)
    this.targetHelper = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xff00ff }))
    this.targetHelper.visible = this.config.camera.targetHelper
    sceneManager.scene.add(this.targetHelper)
  }

  componentWillReceiveProps(nextConfig) {
    // change in light props
    this.ambientLight.intensity = nextConfig.light.intensity

    if (this.ambientLight.color !== nextConfig.light.color)
      this.ambientLight.color = new THREE.Color(new Color(nextConfig.light.color).getRGB())

    this.axesHelper.visible = nextConfig.renderer.axesHelper

    this.targetHelper.visible = nextConfig.camera.targetHelper

    this.config = nextConfig
  }

  update() {
    const controls = this.cameraManager.controls

    this.targetHelper.position.set(controls.target.x, controls.target.y, controls.target.z)
  }
}
