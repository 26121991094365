import VectorHelper from '../../../services/utils/VectorHelper'

export default class ContentItemTransformer {
  constructor(contentItem) {
    if (contentItem.translation)
      this.translation = VectorHelper.convertToVector3(contentItem.translation)

    if (contentItem.rotation)
      this.rotation = VectorHelper.convertToVector3(contentItem.rotation).multiplyScalar(
        Math.PI / 180
      )

    if (contentItem.scale) this.scale = VectorHelper.convertToVector3(contentItem.scale)

    if (contentItem.visible !== null && contentItem.visible !== undefined)
      this.visible = contentItem.visible
  }

  apply(object3D) {
    if (this.translation) object3D.position.add(this.translation)

    if (this.rotation) object3D.rotation.set(this.rotation.x, this.rotation.y, this.rotation.z)

    if (this.scale) object3D.scale.copy(this.scale)

    if (this.visible !== null && this.visible !== undefined) object3D.visible = this.visible
  }
}
