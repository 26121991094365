import Viewer3DApi from '../Viewer3DApi'

export default class NodeFrameManager {
  config
  cameraManager
  sceneManager
  nodeManager

  constructor(config, cameraManager, sceneManager, nodeManager) {
    this.config = config
    this.cameraManager = cameraManager
    this.sceneManager = sceneManager
    this.nodeManager = nodeManager
  }

  componentDidMount(rendererDiv) {
    this.rendererDiv = rendererDiv
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.NODE,
      {
        frameNode: this.frameNode,
        frameNodes: this.frameNodes,
        closeUpNode: this.closeUpNode
      },
      this
    )
  }

  /**
   * Moves the camera view position so that it frames the given node.
   * If the node has an assigned cameraState, the camera will be set to that location.
   * Otherwise, a normal framing will be performed based on its bounding box.
   * @param node {Node} Node object to frame.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.node
   */
  frameNode(node, animation) {
    if (node.cameraState) this.cameraManager.setCameraState(node.cameraState, true)
    else this.cameraManager.frameBox(node.boundingBox, null, animation)
  }

  /**
   * Moves the camera view position so that it frames all the given nodes.
   * @param nodes {Node[]} Nodes to frame.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.node
   */
  frameNodes(nodes, animation) {
    const wholeBoundingBox = this.sceneManager.calculateBoundingBox(
      nodes.map((node) => node.object3d)
    )
    this.cameraManager.frameBox(wholeBoundingBox, null, animation)
  }

  /**
   * Moves the camera view position to a certain distance of the center of the given node.
   * Can be used both to close in on the node and to withdraw from it.
   *
   * @param node {Node} Node on which the camera close up should be performed.
   * @param closeUpDistance {number} Distance to be kept between the node center and the camera position.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.node
   */
  closeUpNode(node, closeUpDistance, animation) {
    const newTarget = node.closeUpPoint()

    this.cameraManager.moveTo(newTarget, closeUpDistance, animation)
  }
}
