import * as THREE from 'three'
import Viewer3DApi from '../Viewer3DApi'

export default class AnimationManager {
  config
  sceneManager
  clock
  animations = []

  constructor(config, sceneManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.clock = new THREE.Clock()

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.ANIMATION,
      {
        startAnimationsLooping: this.startAnimationsLooping,
        startAnimationsOnce: this.startAnimationsOnce,
        pauseAnimations: this.pauseAnimations,
        stopAnimations: this.stopAnimations
      },
      this
    )
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  update() {
    let shouldRender = false

    const delta = this.clock.getDelta()
    for (const sceneObject of this.sceneManager.getSceneObjects()) {
      if (sceneObject.mixer) {
        sceneObject.mixer.update(delta)
        shouldRender = true
      }
    }

    return shouldRender
  }

  onLoadFinished(result) {
    this.animations = result.animations || []

    const initialAnimationState = this.config.animation.initialState
    if (initialAnimationState !== 'paused') {
      switch (initialAnimationState) {
        case 'loopOnce':
          this.startAnimationsOnce(false)
          break
        case 'loopOnceInverted':
          this.startAnimationsOnce(true)
          break
        case 'loopPingPong':
          this.startAnimationsLooping()
          break
      }
    }
  }

  componentWillUnmount() {
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
  }

  /**
   * Starts all animations from all objects, keeping them forever looping in “ping-pong” mode (from the start to end point, then back from end to start point, in a seamless effect).
   * @api viewer3d.animation
   */
  startAnimationsLooping() {
    for (const animation of this.animations) {
      animation.reset()
      animation.timeScale = 1
      animation.enabled = true
      animation.paused = false
      animation.setLoop(THREE.LoopPingPong)

      animation.play()
    }
  }

  /**
   * Runs all animations from all objects, once, then stops.
   * @param invert {boolean} (Optional) If true, the animations should be performed backwards (default is false), otherwise they will be played normally.
   * @api viewer3d.animation
   */
  startAnimationsOnce(invert) {
    for (const animation of this.animations) {
      animation.reset()
      animation.enabled = true
      animation.paused = false
      animation.loop = THREE.LoopRepeat
      animation.repetitions = 1
      animation.timeScale = invert ? -1 : 1
      animation.clampWhenFinished = true

      animation.play()
    }
  }

  /**
   * Pauses all animations from all objects at their current frame.
   * @api viewer3d.animation
   */
  pauseAnimations() {
    for (const animation of this.animations) {
      animation.paused = true
    }
  }

  /**
   * Stops all animations from all objects, resetting their state back to the first frame.
   * @api viewer3d.animation
   */
  stopAnimations() {
    for (const animation of this.animations) {
      animation.stop()
    }
  }
}
