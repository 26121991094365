import * as THREE from 'three'
import Node from './Node.js'

import TWEEN from '@tweenjs/tween.js'
import Color from '../../utils/Color'

export const MARKER_TYPE = 'Marker'

export default class Marker extends Node {
  color

  /**
   * Creates a Marker.
   * @param sprite Sprite object, to be created beforehand with the SpriteCreateHelper.
   */
  constructor(sprite) {
    super(sprite, MARKER_TYPE)

    this.sprite = sprite
    this.options = this.defaultOptions = Object.assign(
      {
        disabledColor: 'rgba(255,255,255,1)',
        normalColor: null,
        hoveredColor: null,
        selectedColor: null,
        duration: 200,
        normalImagePath: '/images/Marker.png',
        hoveredImagePath: null,
        selectedImagePath: null,
        disabledImagePath: null
      },
      sprite.options
    )

    // allow cross origin loading
    this.textureLoader = new THREE.TextureLoader()
    this.textureLoader.crossOrigin = ''

    this.loadImages()

    this.cameraState = null
    // this.boundingBox = new THREE.Box3().setFromObject(this.sprite);
    this.boundingBox = this.sprite.boundingBox
    this.color = null
  }

  loadImages() {
    this.normalImage = this.textureLoader.load(this.options.normalImagePath) // options.normalImagePath

    if (this.options.hoveredImagePath)
      this.hoveredImage = this.textureLoader.load(this.options.hoveredImagePath)

    if (this.options.selectedImagePath)
      this.selectedImage = this.textureLoader.load(this.options.selectedImagePath)

    if (this.options.disabledImagePath)
      this.disabledImage = this.textureLoader.load(this.options.disabledImagePath)
  }

  updateMaterials() {
    let image = this.normalImage
    let color = this.options.normalColor || 'rgba(255,255,255,1)'

    if (!this.enabled) {
      image = this.disabledImage || image
      color = this.options.disabledColor || color
    }

    if (this.hovered) {
      image = this.hoveredImage || image
      color = this.options.hoveredColor || color
    }

    if (this.selected) {
      image = this.selectedImage || image
      color = this.options.selectedColor || color
    }

    this.setMap(image, color)
  }

  /**
   * Sets the image of the sprite and its ghost/transparent twin.
   * @param map Image to be set.
   * @param color
   */
  setMap(map, color) {
    const actualColor = new Color(color)

    const sprite1Material = this.sprite.material
    const sprite2Material = this.sprite.children[0].material

    new TWEEN.Tween({})
      .to({ x: 10 }, 50)
      .onUpdate(function () {
        sprite1Material.map = map
        sprite2Material.map = map
      })
      .start()

    new TWEEN.Tween(sprite1Material.color)
      .to(new THREE.Color(actualColor.getRGB()), this.options.duration)
      .start()

    new TWEEN.Tween(sprite2Material.color)
      .to(new THREE.Color(actualColor.getRGB()), this.options.duration)
      .start()
  }

  isIsFrontOfPlane(plane) {
    return plane.distanceToPoint(this.sprite.position) >= 0
  }

  closeUpPoint() {
    // console.log(this.sprite);
    // return THREE.Vector3(this.sprite.scale.x, this.sprite.scale.y, 1);
    // return this.boundingBox.getCenter(new THREE.Vector3());
    return this.sprite.position
  }
}
