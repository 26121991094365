import * as THREE from 'three'
import Viewer3DApi from '../Viewer3DApi'
import PathHelper from '../services/utils/PathHelper'
import Color from '../utils/Color'
import NodeHelper from '../utils/NodeHelper'
import Equirectangular from '../services/loaders/Equirectangular'
import AnimationHelper from '../services/utils/AnimationHelper'

export default class SkinManager {
  config
  sceneManager
  renderManager
  defaultTextureLoader

  constructor(config, sceneManager, renderManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.renderManager = renderManager

    this.defaultTextureLoader = new THREE.TextureLoader()
    this.defaultTextureLoader.crossOrigin = ''

    this.sceneManager.onLoadFinishEvent.subscribe(this.onSceneLoadFinished, this)
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.SKIN,
      {
        addSkin: this.addSkin,
        setSkin: this.setSkin,
        resetSkin: this.resetSkin,
        getSkinNames: this.getSkinNames,
        getObjectSkins: this.getObjectSkins
      },
      this
    )
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  onSceneLoadFinished(result) {
    this.skins = result.skins || {}
  }

  componentWillUnmount() {
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
  }

  /**
   * Adds a skin to an object in the currently loaded scene.
   * @param skinName {string} Name of the skin
   * @param skin {Skin} Skin object to be added
   * @param objectFilter {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @api viewer3d.skin
   */
  addSkin(skinName, skin, objectFilter) {
    for (const object of this.sceneManager
      .getSceneObjects(objectFilter)
      .filter((x) => x instanceof THREE.Mesh)) {
      let objectSkins = this.skins[object.name]
      if (!objectSkins) objectSkins = this.skins[object.name] = {}

      objectSkins[skinName] = skin
    }
  }

  /**
   * Sets a skin to an object in the currently loaded scene.
   * @param skinName {string} Name of the skin
   * @param [objectFilter] {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @param [cummulative] {boolean} If true, the skin will be applied additively, otherwise the skin will be applied over the original material properties.
   * @param [animation] {AnimationOptions} If a value is set, the transition to the new target will be animated (see animation details in the AnimationOptions object page), otherwise the change will be performed instantly.
   * @api viewer3d.skin
   */
  setSkin(skinName, objectFilter, cummulative, animation) {
    if (!this.skins) return

    if (!cummulative) this.resetSkin(objectFilter)

    const animationHelper = new AnimationHelper(animation)

    this.sceneManager
      .getSceneObjects(objectFilter)
      .filter((x) => x instanceof THREE.Mesh)
      .forEach((object) => {
        // make sure the skin for such an object exists
        const objectSkins = this.skins[object.name]
        if (!objectSkins) return

        // make sure the skin exists
        const skin = objectSkins[skinName]
        if (!skin) return

        if (!object.originalSkinMaterial) {
          object.originalSkinMaterial = object.material.clone()
        }

        this.setSkinInternal(skin, object, animationHelper)
      }, this)
  }

  /**
   * Resets the skin of the selected objects to their original material properties.
   * @param [objectFilter] {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @api viewer3d.skin
   */
  resetSkin(objectFilter) {
    this.sceneManager
      .getSceneObjects(objectFilter)
      .filter((x) => x instanceof THREE.Mesh)
      .forEach((object) => {
        if (object.originalSkinMaterial) {
          object.material = object.originalSkinMaterial
          object.originalSkinMaterial = object.material.clone()
        }
      })

    this.renderManager.updateShouldRenderer(true)
  }

  /**
   * Gets skin names that are available for some or all objects.
   * @return {Skin[]} Flat list of strings with the skin names.
   * @api viewer3d.skin
   */
  getSkinNames() {
    if (this.skins)
      return Object.values(this.skins)
        .reduce((result, val) => result.concat(Object.keys(val)), []) // get only the skin names from the groups
        .filter(NodeHelper.onlyUnique) // get only distinct skins
  }

  /**
   * Gets the available skins for the currently loaded objects.
   * @return {Map<string,Skin[]>} Object containing the skins, grouped by the object that they apply to.
   * @api viewer3d.skin
   */
  getObjectSkins() {
    return this.skins
  }

  setSkinInternal(skin, object, animationHelper) {
    for (const skinProperty in skin) {
      if (!skin.hasOwnProperty(skinProperty)) {
        continue
      }

      const value = skin[skinProperty]
      if (
        this.handleMap(object.material, skinProperty, value, animationHelper) ||
        this.handleColor(object.material, skinProperty, value, animationHelper) ||
        this.handleAlpha(object.material, skinProperty, value, animationHelper)
      ) {
        this.refreshMaterialAndRender(object.material)
      }
    }
  }

  handleMap(material, skinProperty, value, animationHelper) {
    const url = this.config.dataSource.url

    // map entries have special handling
    // because they mean an url that has to be loaded
    if (skinProperty.toLowerCase().includes('map')) {
      animationHelper.animate((updateValue) => {
        if (updateValue > 0.5) {
          if (!value) {
            material[skinProperty] = null
          } else if (skinProperty === 'envMap') {
            const fullUrl = PathHelper.getFullUrl(url, value)

            Equirectangular.load(fullUrl, (texture) => {
              material.envMap = texture

              this.refreshMaterialAndRender(material)
            })
          } else {
            const fullUrl = PathHelper.getFullUrl(url, value)

            this.defaultTextureLoader.load(fullUrl, (texture) => {
              material[skinProperty] = texture
              this.refreshMaterialAndRender(material)
            })
          }
        }
      })

      return true
    }
  }

  handleColor(material, skinProperty, value, animationHelper) {
    if (skinProperty === 'color' || skinProperty === 'emissive' || skinProperty === 'specular') {
      const color = new Color(value)

      animationHelper.animateColor(
        material[skinProperty],
        new THREE.Color(color.getRGB()),
        (value) => {
          material[skinProperty] = value
          this.refreshMaterialAndRender(material)
        }
      )
      return true
    }
  }

  handleAlpha(material, skinProperty, value, animationHelper) {
    if (skinProperty === 'alpha') {
      animationHelper.animateNumeric(material.opacity, value, (value) => {
        material.opacity = value
        material.transparent = material.opacity < 1
        material.depthWrite = !material.transparent

        this.refreshMaterialAndRender(material)
      })

      return true
    }
  }

  refreshMaterialAndRender(material) {
    material.needsUpdate = true
    this.renderManager.updateShouldRenderer(true)
  }
}
