import Viewer3DApi from '../Viewer3DApi'

export default class ExtendedCameraManager {
  cameraManager
  renderManager
  nodeSelectionManager
  sceneManager

  constructor(cameraManager, renderManager, sceneManager, nodeSelectionManager) {
    this.cameraManager = cameraManager
    this.renderManager = renderManager
    this.nodeSelectionManager = nodeSelectionManager
    this.sceneManager = sceneManager

    this.cameraManager.cameraChangeEvent.subscribe(this.onShouldUpdateRender.bind(this), this)
  }

  onShouldUpdateRender() {
    this.renderManager.updateShouldRenderer(true)
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.CAMERA,
      {
        takeScreenshot: this.takeScreenshot,
        getMouse3DCoordinates: this.getMouse3DCoordinates,
        reset: this.resetCamera
      },
      this
    )
  }

  componentWillUnmount() {
    this.cameraManager.cameraChangeEvent.unsubscribeAll(this)
  }

  /**
   * Takes a screenshot of the Webgl canvas.
   * @returns {string}  The image data that was captured.
   * @api viewer3d.camera
   */
  takeScreenshot() {
    return this.renderManager.getScreenshot()
  }

  /**
   * Resets the camera orientation to the initial direction and then frames the whole scene in the screen. Works only if a scene is loaded.
   * @param animation {AnimationOptions} If a value is set, the transition to the new location will be animated according to the defined options, otherwise the change will be performed instantly.
   * @api viewer3d.camera
   * @alias reset
   */
  resetCamera(animation) {
    this.sceneManager.frameScene(animation, this.cameraManager.getOriginalDirection())
  }

  /**
   * Gets the last 3D position resulting from an intersection between a ray cast starting from the mouse screen position.
   * @returns {Vector3} Vector3 object indicating the position, or null, if no 3D object was intersected.
   * @api viewer3d.camera
   */
  getMouse3DCoordinates() {
    return this.nodeSelectionManager.getMouse3DCoordinates()
  }
}
