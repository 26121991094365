import PickingBox, { PICKING_BOX_TYPE } from './nodes/PickingBox'

export default class PickingBoxManager {
  config
  sceneManager
  nodeManager

  constructor(config, sceneManager, nodeManager) {
    this.config = config

    this.sceneManager = sceneManager

    this.nodeManager = nodeManager
    this.nodeManager.nodePreparationEvent.subscribe(this.onNodePreparation, this)
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  componentWillUnmount() {
    this.nodeManager.nodePreparationEvent.unsubscribeAll(this)
  }

  onNodePreparation() {
    this.buildPickingBoxesOnMeshes()
  }

  buildPickingBoxesOnMeshes() {
    // for use in isolation, copy the reference to the original material
    for (const mesh of this.sceneManager.getMeshChildren()) {
      this.nodeManager.buildNode(mesh, 'PICK', PICKING_BOX_TYPE, PickingBox)
    }
  }

  decodeSid(sid) {
    const strSplit = sid.split('__')

    // TODO: check what cases fit this
    if (strSplit.length > 3) {
      return strSplit[2].concat('_-').concat(strSplit[3])
    } else {
      return strSplit[2]
    }
  }
}
