import * as THREE from 'three'

export default class MeshManager {
  config
  sceneManager

  constructor(config, sceneManager) {
    this.config = config
    this.sceneManager = sceneManager

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
  }

  componentWillReceiveProps(nextConfig) {
    if (this.config.renderer.enableDoubleSide !== nextConfig.renderer.enableDoubleSide) {
      this.updateDoubleSide(nextConfig.renderer.enableDoubleSide)
    }

    this.config = nextConfig
  }

  onLoadFinished() {
    this.updateDoubleSide(this.config.renderer.enableDoubleSide)
  }

  updateDoubleSide(enableDoubleSide) {
    for (const mesh of this.sceneManager.getMeshChildren()) {
      mesh.material.side = enableDoubleSide ? THREE.DoubleSide : THREE.FrontSide
    }
  }
}
