export default {
  getFullUrl(baseUrl, fileUrl) {
    if (!fileUrl) return
    return !this.isPathAbsolute(fileUrl) ? baseUrl + fileUrl : fileUrl
  },

  isPathAbsolute(path) {
    return /^(?:\/|[a-z]+:\/\/)/.test(path)
  }
}
