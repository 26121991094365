import PathHelper from '../../services/utils/PathHelper'
import JSONLoader from '../../services/loaders/JSONLoader'

export default class SkinContentLoader {
  async loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    const skinsFileUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const data = await JSONLoader.loadJsonObject(skinsFileUrl)

    output.skins = Object.assign({}, output.skins, data)
    onLoadFinished(undefined, output)
  }
}
