import * as THREE from 'three'
import Node from './Node.js'
import Color from '../../utils/Color'

import TWEEN from '@tweenjs/tween.js'

export const PICKING_BOX_TYPE = 'PickingBox'

export default class PickingBox extends Node {
  constructor(mesh) {
    super(mesh, PICKING_BOX_TYPE)

    this.defaultOptions = {
      disabledFillColor: 'rgba(0,0,0,0.3)',
      normalFillColor: 'rgba(0,0,0,0)',
      hoveredFillColor: null,
      selectedFillColor: null,

      disabledBorderColor: null,
      normalBorderColor: null,
      hoveredBorderColor: 'rgba(0,0,255,0.5)',
      selectedBorderColor: 'rgba(0,255,0,0.3)',

      nearAlpha: 0.05,
      nearDistance: 1000,
      farDistance: 2000,

      duration: 200
    }

    this.mesh = mesh

    this.originalMap = this.mesh.material.map

    mesh.material = mesh.material.clone()

    this.baseColor = '#' + this.mesh.material.color.getHexString()

    this.fillColor = null
    this.borderColor = null

    this.boundingBox = new THREE.Box3().setFromObject(mesh)
    this.boundingBoxVertices = PickingBox.getBoundBoxVertices(this.boundingBox)
    this.firstRun = true
  }

  updateMaterials() {
    this.updateBaseTexture()

    let color = this.fillColor || this.options.normalFillColor || this.baseColor || 'rgba(0,0,0,0)'
    let borderColor = this.borderColor || this.options.normalBorderColor || 'rgba(0,0,0,0)'

    if (!this.enabled) {
      color = this.options.disabledFillColor || color
      borderColor = this.options.disabledBorderColor || borderColor
    }

    if (this.hovered) {
      color = this.options.hoveredFillColor || color
      borderColor = this.options.hoveredBorderColor || borderColor
    }

    if (this.selected) {
      color = this.options.selectedFillColor || color
      borderColor = this.options.selectedBorderColor || borderColor
    }

    const duration = this.firstRun ? 0 : this.options.duration

    if (!this.mesh.visible) return

    this.setMaterial(color, duration)

    // this will be processed by the OutlineManager
    this.mesh.borderColor = borderColor

    this.firstRun = false
  }

  updateBaseTexture() {
    const map =
      this.options.disableTextures && (this.selected || this.hovered) ? null : this.originalMap
    if (map !== this.mesh.material.map) {
      this.mesh.material.map = map
      this.mesh.material.needsUpdate = true
    }
  }

  setMaterial(color, duration) {
    const material = this.mesh.material

    const actualColor = new Color(color)

    this.baseAlpha = actualColor.getAlpha()

    this.updateAlpha()

    new TWEEN.Tween(material.color).to(new THREE.Color(actualColor.getRGB()), duration).start()
  }

  onCameraChange(cameraPosition) {
    this.lastCameraPosition = cameraPosition
    this.updateAlpha()
  }

  updateAlpha() {
    /* if (!this.lastCameraPosition)
      return; */

    let newAlpha = this.baseAlpha

    // these can become viewer parameters
    const farAlpha = this.baseAlpha
    let nearAlpha = this.options.nearAlpha
    const nearDistance = this.options.nearDistance || 1000
    const farDistance = this.options.farDistance || 2000

    // experiment
    if (nearAlpha && this.lastCameraPosition) {
      nearAlpha = Math.min(this.baseAlpha, nearAlpha)

      const boundingCenter = this.boundingBox.getCenter(new THREE.Vector3())

      const distance = this.lastCameraPosition.clone().sub(boundingCenter).length()
      const constrainedDistance = Math.max(Math.min(distance, farDistance), nearDistance)
      const relativeDistance = (constrainedDistance - nearDistance) / (farDistance - nearDistance)

      newAlpha = nearAlpha + relativeDistance * (farAlpha - nearAlpha)
    }

    // if there's a newAlpha, we set the new opacity level, otherwise use the baseAlpha
    const material = this.mesh.material
    material.opacity = newAlpha
    material.transparent = material.opacity < 1
    material.depthWrite = !material.transparent
  }

  isIsFrontOfPlane(plane) {
    return this.boundingBoxVertices.some((x) => plane.distanceToPoint(x) >= 0)
  }

  static getBoundBoxVertices(boundingBox) {
    const min = boundingBox.min
    const max = boundingBox.max

    return [
      min,
      max,
      new THREE.Vector3(min.x, min.y, max.z),
      new THREE.Vector3(min.x, max.y, min.z),
      new THREE.Vector3(max.x, min.y, min.z),
      new THREE.Vector3(max.x, max.y, min.z),
      new THREE.Vector3(max.x, min.y, max.z),
      new THREE.Vector3(min.x, max.y, max.z)
    ]
  }

  closeUpPoint() {
    return this.boundingBox.getCenter(new THREE.Vector3())
  }
}
