import * as THREE from 'three'
import Viewer3DApi from '../Viewer3DApi'
import Color from '../utils/Color'
import ObjectHelper from '../services/utils/ObjectHelper'

export default class IsolationManager {
  config
  sceneManager
  renderManager
  nodeSelectionManager

  constructor(config, sceneManager, nodeSelectionManager, renderManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.renderManager = renderManager
    this.nodeSelectionManager = nodeSelectionManager

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
    this.nodeSelectionManager.nodeSelectionChangedEvent.subscribe(this.onNodeSelectionChanged, this)
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.NODE,
      {
        isolateNodes: this.isolateNodes
      },
      this
    )
  }

  onNodeSelectionChanged(selectedNodes) {
    if (this.config.nodes.selectToIsolate) {
      this.isolateNodes(selectedNodes)
    }
  }

  onLoadFinished() {
    // for use in isolation, copy the reference to the original material
    for (const object of this.sceneManager.getMeshChildren()) {
      object.originalMaterial = object.material
    }
  }

  componentWillUnmount() {
    this.sceneManager.onLoadFinishEvent.unsubscribeAll(this)
    this.nodeSelectionManager.nodeSelectionChangedEvent.unsubscribe(this)
  }

  /**
   * Performs or resets isolation based on the definition in the data.json or default node association.
   * @param nodes {Node[]} the nodes to isolate. If empty, isolation will be reverted, so that no nodes will appear isolated.
   * @param [intensity] {number} Opacity to apply to the non-isolated meshes (0 being transparent, 1 fully opaque). Optional, if not defined, the value of the isolationAlpha is applied.
   * @api viewer3d.node
   */
  isolateNodes(nodes, intensity) {
    let allTargetModelFilters = []

    // filter those nodes with the targetModels field and merge the contents of the arrays
    for (const node of nodes) {
      if (
        node.targetModels !== null &&
        node.targetModels !== undefined &&
        node.targetModels.length > 0
      ) {
        allTargetModelFilters = allTargetModelFilters.concat(node.targetModels)
      } else if (this.config.nodes.useDefaultIsolation) {
        // if useDefaultIsolation is enabled, we will check for default associations
        // according to the name. But it might happen that it won't match anything
        // (because it hasn't been configured yet). In that case, we won't apply isolation
        // but we could just apply the expression unconditionally if we don't want this behavior
        const expression = node.sid + '.*'
        const regEx = new RegExp('^' + expression + '$')

        if (this.sceneManager.getMeshChildren().some((x) => !x.node && regEx.test(x.name)))
          allTargetModelFilters.push(expression)
      }
    }

    this.isolate(allTargetModelFilters, intensity)
  }

  isolate(allTargetModelFilters, intensity) {
    const actualColor = new Color(this.config.nodes.isolationColor)
    const actualIntensity = ObjectHelper.coalesce(intensity, actualColor.getAlpha())

    const defaultIsolateMaterial = new THREE.MeshPhongMaterial()
    defaultIsolateMaterial.color = new THREE.Color(actualColor.getRGB())
    defaultIsolateMaterial.opacity = actualIntensity
    defaultIsolateMaterial.transparent = defaultIsolateMaterial.opacity < 1
    defaultIsolateMaterial.depthWrite = !defaultIsolateMaterial.transparent

    const regExs = allTargetModelFilters.map((x) => new RegExp('^' + x + '$'))
    for (const currentThreeObject of this.sceneManager.getMeshChildren()) {
      // do not apply to nodes or objects without materials, just other models
      if (currentThreeObject.node || !currentThreeObject.material) continue

      // if there's a newAlpha, we set the new opacity level, otherwise use the baseAlpha
      // const material = currentThreeObject.material;
      const useIsolateMaterial =
        allTargetModelFilters.length > 0 && !regExs.some((x) => x.test(currentThreeObject.name))

      // apply the alpha transparency to the mesh and all its children
      for (const obj of [currentThreeObject].concat(
        this.sceneManager.getMeshChildren(currentThreeObject)
      )) {
        obj.material = useIsolateMaterial ? defaultIsolateMaterial : obj.originalMaterial
      }
    }

    this.renderManager.updateShouldRenderer(true)
  }
}
