import * as THREE from 'three'

export default class Node {
  object3d
  objectType
  linkedScene
  enabled
  selected
  hovered
  cameraState
  options
  defaultOptions
  individualOptions
  boundingBox
  targetModels

  /**
   * Declare a Node class.
   */
  constructor(object3d, objectType) {
    this.name = object3d.name
    this.object3d = object3d
    this.objectType = objectType

    this.enabled = true
    this.selected = false
    this.hovered = false
    this.cameraState = null
    this.linkedScene = null

    // these might only apply or be assigned within some subclasses
    this.boundingBox = null
    this.borderColor = null
    this.fillColor = null
  }

  applyDefaultOptions(nodeTypes) {
    const options = nodeTypes[this.objectType]
    this.options = Object.assign({}, this.defaultOptions, options, this.individualOptions)
    this.updateMaterials()
  }

  /**
   * This should be overridden in the subclasses, updating its materials or otherwise visual appearance.
   */
  updateMaterials() {}

  onCameraChange() {}

  isIsFrontOfPlane() {
    return false
  }

  closeUpPoint() {
    return new THREE.Vector3()
  }
}
