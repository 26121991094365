export default {
  toRadians: (degrees) => {
    return (degrees * Math.PI) / 180
  },
  toDegrees: (radians) => {
    return radians * (180 / Math.PI)
  },
  random: (min, max) => {
    return Math.random() * (max - min) + min
  },
  randomInt: (min, max) => {
    return Math.floor(Math.random() * (max - min) + min)
  },
  clamp: (value, min, max) => {
    return Math.min(Math.max(value, min), max)
  }
}
