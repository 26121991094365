import * as THREE from 'three'
import { isEqual, minBy } from 'lodash'
import MathHelper from '../services/utils/MathHelper'
import Viewer3DApi from '../Viewer3DApi'
import ObjectHelper from '../services/utils/ObjectHelper'

class TemplateInfo {
  templateMesh
  instanceList
  originalInstanceList
  needsUpdate
  instanceSceneObject

  constructor(templateMesh, instanceList){
    this.templateMesh = templateMesh
    this.instanceList = instanceList
    this.originalInstanceList = [...instanceList]
    this.needsUpdate = true
    this.instanceSceneObject = null
  }
}

export default class InstancedMeshManager {
  config
  sceneManager
  templateInfos

  constructor(config, sceneManager, renderManager) {
    this.config = config
    this.sceneManager = sceneManager
    this.renderManager = renderManager

    this.sceneManager.onLoadFinishEvent.subscribe(this.onLoadFinished, this)
  }

  componentWillReceiveProps(nextConfig) {
    this.config = nextConfig
  }

  exposeAPI(api) {
    api.register(
      Viewer3DApi.TYPES.INSTANCING,
      {
        getTemplatesAndInstances: this.getTemplatesAndInstances,
        changeInstances: this.changeInstances,
        resetInstances: this.resetInstances,
        updateInstances: this.updateInstances,
      },
      this
    )
  }


  /**
   * Gets the registered template names and their instances.
   * @return {Map<string,string[]>} Map of template names, each entry containing the instances currently assigned this template.
   * @api viewer3d.instancing
   */
  getTemplatesAndInstances(){
    return Object.entries(this.templateInfos).reduce((result, [key, value]) => {
      result[key] = value.instanceList.map(x => x.name);
      return result
    }, {})
  }


  /**
   * Resets the instances to their original template attributions.
   * @param [skipUpdate] {boolean} If true, the instance meshes won't be immediately updated. This is useful if you wish to call several different instance changing functions at once. As the update process is an expensive operation, it should only be performed once.
   * @api viewer3d.instancing
   */
  resetInstances(skipUpdate){

    for (let templateInfo of Object.values(this.templateInfos)){
      if(!isEqual(templateInfo.instanceList, templateInfo.originalInstanceList)){
        templateInfo.instanceList = [...templateInfo.originalInstanceList]
        templateInfo.needsUpdate = true
      }
    }

    if(!skipUpdate){
      this.updateInstances();
    }
  }


  /**
   * Changes the template assigned to instances.
   * @param [targetTemplateName] {string} Name of the template to change to. Leave undefined or null to select none (meaning the instances will just be removed).
   * @param [objectFilter] {string|string[]} Name of the object (or list of names) that the operation should be applied to. Leave null/undefined to apply to all.
   * @param [cumulative] {boolean} If true, the change will be applied additively, otherwise all instances will be reset before applying this change.
   * @param [skipUpdate] {boolean} If true, the instance meshes won't be immediately updated. This is useful if you wish to call several different instance changing functions at once. As the update process is an expensive operation, it should only be performed once.
   * @api viewer3d.instancing
   */
  changeInstances(targetTemplateName, objectFilter, cumulative, skipUpdate){

    if(!cumulative){
      this.resetInstances(false)
    }

    const instancesToRelocate = []
    const objectFilterSet = Array.isArray(objectFilter) ? new Set(objectFilter) : null
    for (let [templateName, templateInfo] of Object.entries(this.templateInfos)){

      //if the target template is this one, we don't need to look into the instances
      //already inside this templateInfo
      if(targetTemplateName === templateName){
        continue
      }

      const newInstanceList = [];
      for (const instance of templateInfo.instanceList) {

        //if the item is included in the filter, it must go out
        if(ObjectHelper.appliesToObject(instance, objectFilterSet)){
          instancesToRelocate.push(instance)
          templateInfo.needsUpdate = true;
        }
        else
          newInstanceList.push(instance)
      }
      // if we change the list, make
      templateInfo.instanceList = newInstanceList;
    }

    //if there is no target template, then the goal was just removal
    //and that has been done so far
    //but if not...
    if(targetTemplateName){

      let targetTemplate = this.templateInfos[targetTemplateName]
      if(targetTemplate){

        for (const instanceToRelocate of instancesToRelocate) {
          targetTemplate.instanceList.push(instanceToRelocate);
        }

        targetTemplate.needsUpdate = true
      }
      else{
        console.warn(`The indicated template ${targetTemplate} is not defined.`)
      }
    }

    if(!skipUpdate){
      //call the update function
      this.updateInstances();
    }
  }



  onLoadFinished() {

    this.templateInfos = this.loadTemplateInfos();

    this.updateInstances();
  }


  /**
   * Updates the mesh instances on the screen, if there are pending changes. This is called by changeInstances, if skipUpdate = false.
   *
   * This can be a somewhat expensive operation if called after every small change. If many changes are to be chained, it is more efficient to call changeInstances(...) with skipUpdate = true and then call this function at the end (similar to a flush() operation).
   *
   * If there are no pending changes, then this function doesn't incur any overhead.
   * @api viewer3d.instancing
   */
  updateInstances(){
    this.updateSceneInstanceObjects(this.templateInfos);
  }


  loadTemplateInfos() {

    const templateMap = {}
    const instancesMap = {}

    // iterate over objects and identify and separate the instances and samples
    for (const sceneObject of this.sceneManager.getSceneObjects()) {
      const splitName = sceneObject.name.split('_')

      if (sceneObject.name.startsWith('TEMPLATE_')) {
        const sampleName = splitName[1]
        templateMap[sampleName] = sceneObject

        this.sceneManager.removeSceneObject(sceneObject)

      } else if (sceneObject.name.startsWith('INSTANCE_')) {
        const instanceSampleName = splitName[1]

        if (!instancesMap[instanceSampleName])
          instancesMap[instanceSampleName] = [sceneObject]
        else
          instancesMap[instanceSampleName].push(sceneObject)

        this.sceneManager.removeSceneObject(sceneObject)
      }
    }

    //look at all the keys either defined in templates or instances
    const templateInfos = {}
    for (const templateName of Object.keys(instancesMap).concat(Object.keys(templateMap))) {

      const templateMesh = templateMap[templateName]
      const instancesList = instancesMap[templateName];
      if (!templateMesh) {
        console.error(`There are instances of sample ${templateName},
          but there is no template object with that name.`)
        continue
      }

      if (!instancesList) {
        console.error(`There are no instances of the sample ${templateName}.`)
        continue
      }

      templateInfos[templateName] = new TemplateInfo(templateMesh, instancesList)
    }

    return templateInfos;
  }


  updateSceneInstanceObjects(templateInfos) {

    // now create one instanced mesh for each sample
    for (const [templateName, templateInfo] of Object.entries(templateInfos)) {

      const templateMesh = templateInfo.templateMesh
      const instances = templateInfo.instanceList

      const instancedMeshesObject = new THREE.InstancedMesh(
        templateMesh.geometry.clone(),
        Array.isArray(templateMesh.material)
          ? templateMesh.material.map((x) => x.clone())
          : templateMesh.material.clone(),
        instances.length
      )
      instancedMeshesObject.name = "INSTANCES_" + templateName
      instancedMeshesObject.instanceMatrix.setUsage(THREE.DynamicDrawUsage)

      if(!templateInfo.needsUpdate)
        continue

      for (let i = 0; i < instances.length; i++) {
        const dummy = new THREE.Object3D()
        const instanceMesh = instances[i]
        const positions = instanceMesh.geometry.attributes.position.array

        //assumes triangle with counter-clockwise orientation
        const v0 = new THREE.Vector3(positions[0], positions[1], positions[2])
        const v1 = new THREE.Vector3(positions[3], positions[4], positions[5])
        const v2 = new THREE.Vector3(positions[6], positions[7], positions[8])

        const vertices = [v0,v1,v2];
        let verticesExtra = [];
        for(let i = 0; i < 3; i++){
          const currentVertex = vertices[i];
          const previousVertex = this.getCircular(vertices,i-1);
          const nextVertex = this.getCircular(vertices,i+1);
          const previousDirection = previousVertex.clone().sub(currentVertex);
          const nextDirection = nextVertex.clone().sub(currentVertex);
          const angle = MathHelper.toDegrees(previousDirection.angleTo(nextDirection));

          verticesExtra.push({
            vertex : vertices[i],
            angle : angle,
            lookTo: nextVertex,
            previousSize: previousDirection.length(),
            nextSize: nextDirection.length()
          })
        }

        const vertexAt90Degrees = minBy(verticesExtra, (b) => Math.abs(b.angle - 90));
        const xScale = vertexAt90Degrees.previousSize;
        const zScale = vertexAt90Degrees.nextSize;
        const yScale = Math.min(xScale, zScale);

        dummy.position.copy(v2)
        dummy.lookAt(vertexAt90Degrees.lookTo);
        dummy.scale.copy(new THREE.Vector3(xScale, yScale, zScale))
        dummy.updateMatrix()
        instancedMeshesObject.setMatrixAt(i, dummy.matrix)
      }

      //if an object for this template already existed, remove it
      if(templateInfo.instanceSceneObject)
        this.sceneManager.group.remove(templateInfo.instanceSceneObject)

      templateInfo.needsUpdate = false

      this.sceneManager.group.add(templateInfo.instanceSceneObject = instancedMeshesObject)
      this.renderManager.updateShouldRenderer(true)
    }
  }


  getCircular(vertices, index) {
    const len = vertices.length;
    return vertices[(index % len + len) % len]
  }
}
