import * as THREE from 'three'
import ObjectHelper from '../services/utils/ObjectHelper'

export default class SpriteCreateHelper {

  /**
   * Creates an object contains two sprites, one of them translucent and always visible. This create a sprite that is visible
   * even behind other objects.
   *
   * @param path
   * @param position
   * @param scale
   * @param center
   * @param relative
   * @param minSize
   * @param maxSize
   * @returns {Sprite}
   */
  static create(path, position, scale, center, relative, minSize, maxSize) {
    const sprite = new THREE.Sprite()
    const transparentSprite = new THREE.Sprite()

    const textureLoader = new THREE.TextureLoader()
    textureLoader.crossOrigin = ''

    scale = scale || 1

    sprite.relative = relative

    sprite.center = center
      ? new THREE.Vector2(ObjectHelper.coalesce(center.x, 0), ObjectHelper.coalesce(center.y, 0))
      : new THREE.Vector2(0, 0)

    sprite.boundingBox = new THREE.Box3(new THREE.Vector3(), new THREE.Vector3(1, 1, 1))

    const spriteMap = textureLoader.load(path, () => {
      const width = spriteMap.image.naturalWidth
      const height = spriteMap.image.naturalHeight

      sprite.scale.set(width * scale, height * scale, 1)

      sprite.originalScale = sprite.scale.clone()

      const maxComponent = Math.max(sprite.scale.x, sprite.scale.y)
      const positionVector = new THREE.Vector3(position.x, position.y, position.z)
      const min = positionVector
        .clone()
        .sub(new THREE.Vector3(maxComponent / 2, maxComponent / 2, maxComponent / 2))
      const max = positionVector
        .clone()
        .add(new THREE.Vector3(maxComponent / 2, maxComponent / 2, maxComponent / 2))

      sprite.boundingBox = new THREE.Box3(min, max)

      if (sprite.loadFinished) sprite.loadFinished()
    })

    sprite.material = new THREE.SpriteMaterial({
      map: spriteMap,
      color: 0xffffff,
      sizeAttenuation: true
    })
    transparentSprite.material = new THREE.SpriteMaterial({
      map: spriteMap,
      color: 0xffffff,
      opacity: 0.3,
      depthTest: false,
      sizeAttenuation: true
    })
    transparentSprite.center = sprite.center.clone()

    sprite.position.copy(position)

    sprite.add(transparentSprite)
    sprite.relative = relative
    sprite.minSize = minSize
    sprite.maxSize = maxSize

    return sprite
  }

  static createAsync(path, options, callback) {
    const sprite = new THREE.Sprite()
    const transparentSprite = new THREE.Sprite()

    const textureLoader = new THREE.TextureLoader()
    textureLoader.crossOrigin = ''

    const position = options.position || new THREE.Vector3(0, 0, 0)
    const scale = options.scale || 1

    sprite.relative = options.relative
    sprite.minSize = options.minSize
    sprite.maxSize = options.maxSize

    sprite.center = options.center
      ? new THREE.Vector2(
          ObjectHelper.coalesce(options.center.x, 0),
          ObjectHelper.coalesce(options.center.y, 0)
        )
      : new THREE.Vector2(0, 0)

    const spriteMap = textureLoader.load(path, () => {
      const width = spriteMap.image.naturalWidth
      const height = spriteMap.image.naturalHeight

      sprite.scale.set(width * scale, height * scale, 1)

      sprite.originalScale = sprite.scale.clone()

      const maxComponent = Math.max(sprite.scale.x, sprite.scale.y)
      const vector2 = sprite.center
        .clone()
        .multiply(new THREE.Vector2(sprite.scale.x, sprite.scale.y))
      const vector3 = new THREE.Vector3(position.x, position.y, position.z)
      const minPosition = vector3
        .clone()
        .sub(new THREE.Vector3(vector2.x, vector2.y, maxComponent / 2))

      sprite.boundingBox = new THREE.Box3(
        minPosition,
        minPosition.clone().add(new THREE.Vector3(maxComponent, maxComponent, maxComponent))
      )

      callback(sprite)
    })

    sprite.material = new THREE.SpriteMaterial({
      map: spriteMap,
      color: 0xffffff,
      sizeAttenuation: true
    })
    transparentSprite.material = new THREE.SpriteMaterial({
      map: spriteMap,
      color: 0xffffff,
      opacity: 0.3,
      depthTest: false,
      sizeAttenuation: true
    })
    transparentSprite.center = sprite.center.clone()

    sprite.position.copy(position)
    sprite.add(transparentSprite)
  }
}
