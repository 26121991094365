import * as THREE from 'three'
import { HDRCubeTextureLoader } from 'three/examples/jsm/loaders/HDRCubeTextureLoader.js'
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader.js'

export default class Equirectangular {
  constructor(renderer) {
    this.renderer = renderer
    this.scene = new THREE.Scene()
    this.crossOrigin = '*'
    this.loaders = {
      hdr: new HDRCubeTextureLoader(),
      exr: new EXRLoader()
    }
  }

  static initialize(renderer) {
    Equirectangular.instance = new Equirectangular(renderer)
  }

  static load(url, manager, crossOrigin = '*', onLoad, onProgress, onError) {
    manager = this.manager !== undefined ? manager : THREE.DefaultLoadingManager

    if (!Equirectangular.instance) throw new Error('Equirectagular has not been initialized!')

    let texture
    const textureType = url.split('.').pop()

    switch (textureType.toLowerCase()) {
      // @TODO: one day setup HDR EXR
      // case 'hdr':
      //   texture = Equirectangular.instance.loaders.hdr.load(url)
      //   texture.magFilter = THREE.LinearFilter
      //   break
      // case 'exr':
      //   texture = Equirectangular.instance.loaders.exr.load(url)
      //   Equirectangular.instance.loaders.exr.setCrossOrigin(
      //     Equirectangular.instance.crossOrigin
      //   )
      //   texture = Equirectangular.instance.pmremGenerator.fromEquirectangular(
      //     texture
      //   )
      //   break

      default: {
        const loader = new THREE.TextureLoader(manager)
        if (loader.setCrossOrigin) loader.setCrossOrigin(crossOrigin)

        texture = loader.load(url)
        texture.encoding = THREE.sRGBEncoding
        texture.mapping = THREE.EquirectangularReflectionMapping
        break
      }
    }

    return texture
  }

  static convert(img) {
    return Equirectangular.instance.convert(img, 1024)
  }
}
