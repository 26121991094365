export class SceneInfo {
  name
  content
  meta

  /**
   * @var {Map<String,NodeInfo>}
   */
  nodes

  /**
   * @var {boolean}
   */
  default

  options

  constructor(name) {
    this.name = name
  }
}
