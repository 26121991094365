import PathHelper from '../../services/utils/PathHelper'
import JSONLoader from '../../services/loaders/JSONLoader'
import SpriteCreateHelper from '../../utils/SpriteCreateHelper'
import ContentItemTransformer from './common/ContentItemTransformer'

export default class MarkerContentLoader {
  async loadContent(contentItem, output, dataSource, onLoadProgress, onLoadFinished) {
    output.objects = output.objects || []

    const fullUrl = PathHelper.getFullUrl(dataSource.url, contentItem.url)
    const contentItemTransformer = new ContentItemTransformer(contentItem)
    const data = await JSONLoader.loadJsonObject(fullUrl)
    const resources = data.resources

    let promise = Promise.resolve()

    for (const key in data.markers) {
      const marker = data.markers[key]

      promise = promise.then(
        () =>
          new Promise((resolve) => {
            if (marker.resource) {
              const resource = resources[marker.resource]

              const options = {
                normalImagePath: PathHelper.getFullUrl(dataSource.url, resource.normal),
                hoveredImagePath: PathHelper.getFullUrl(dataSource.url, resource.hovered),
                selectedImagePath: PathHelper.getFullUrl(dataSource.url, resource.selected),
                disabledImagePath: PathHelper.getFullUrl(dataSource.url, resource.disabled)
              }

              SpriteCreateHelper.createAsync(options.normalImagePath, marker, (sprite) => {
                sprite.name = marker.name || ''
                sprite.options = options

                contentItemTransformer.apply(sprite)

                output.objects.push(sprite)

                resolve()
              })
            }
          })
      )
    }

    promise.then(() => {
      onLoadFinished(undefined, output)
    })
  }
}
