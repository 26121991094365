import EventEmitter from 'events'

export class ApiObject {
  constructor() {
    this.apis = {}
  }

  /**
   * Registers all the functions of the given object
   * @param apiName
   * @param functionSet
   * @param object (Opcional) the base object, so that binding can be performed automatically
   */
  register(apiName, functionSet, object) {
    // spare the repeated job of calling bind for all api functions
    if (object) {
      for (const funcKey of Object.keys(functionSet)) {
        try {
          functionSet[funcKey] = functionSet[funcKey].bind(object)
        } catch (e) {
          console.error('Could not bind function "' + funcKey + '": ', e)
        }
      }
    }

    this.apis[apiName] = Object.assign({}, this.apis[apiName], functionSet)
  }

  get(apiName) {
    return this.apis[apiName]
  }

  addEventListener(event, callback) {
    EventEmitter.on(event, callback)
  }

  removeEventListener(event, callback) {
    EventEmitter.removeListener(event, callback)
  }

  removeAllEventListeners(event) {
    EventEmitter.removeAllListeners(event)
  }
}
